import jQuery, { css } from "jquery";
window.$ = window.jQuery = jQuery;

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { SplitText } from "gsap/SplitText";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

gsap.registerPlugin(ScrollTrigger, SplitText, ScrollToPlugin);
gsap.config({ nullTargetWarn: false });

export default function artistArchive() {
	if ($("#viewOptions").length) {
		gsap.to("#viewOptions", {
			scrollTrigger: {
				trigger: "#viewOptions",
				start: "top bottom",
			},
			opacity: 1,
			duration: 2,
			delay: 1,
		});

		gsap.fromTo(
			"#listView > div",
			{
				autoAlpha: 0,
			},
			{
				autoAlpha: 1,
				stagger: {
					each: 0.1,
					grid: "auto",
				},
				duration: 0.5,
				onStart: () => {
					gsap.timeline()
						.fromTo(
							"#feature_panel_section_nav",
							{
								autoAlpha: 0,
							},
							{
								autoAlpha: 1,
								duration: 0.15,
							}
						)
						.fromTo(
							"#feature_panel_section_nav ul li",
							{
								y: 100,
								autoAlpha: 0,
							},
							{
								y: 0,
								autoAlpha: 1,
								stagger: 0.1,
								duration: 0.25,
							}
						);
				},
			}
		);
	}

	$("#gridView .col-span-1").each(function (index) {
		ScrollTrigger.create({
			id: "artistsArchive" + index,
			trigger: $(this),
			start: "top bottom",
			// markers: true,
			onEnter: () => {
				gsap.fromTo(
					$(this).find(".artistArchivePost"),
					{
						y: 50,
						opacity: 0,
					},
					{
						y: 0,
						opacity: 1,
						duration: 2,
						ease: "power1.out",
					}
				);
			},
			onLeave: () => {
				gsap.to($(this).find(".artistArchivePost"), {
					opacity: 0,
				});
			},
			onLeaveBack: () => {
				gsap.to($(this).find(".artistArchivePost"), {
					opacity: 0,
				});
			},
			onEnterBack: () => {
				gsap.fromTo(
					$(this).find(".artistArchivePost"),
					{
						y: -50,
						opacity: 0,
					},
					{
						y: 0,
						opacity: 1,
						duration: 2,
						ease: "power1.out",
					}
				);
			},
		});
	});
}
