import "./style.scss";
import jQuery, { css } from "jquery";
window.$ = window.jQuery = jQuery;

import { debounce, initial, random, reduce, set, uniqueId } from "lodash";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { SplitText } from "gsap/SplitText";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

gsap.registerPlugin(ScrollTrigger, SplitText, ScrollToPlugin);
gsap.config({ nullTargetWarn: false });

// import LazyLoad from "vanilla-lazyload";

var Masonry = require("masonry-layout");
var jQueryBridget = require("jquery-bridget");
import imagesLoaded from "imagesloaded";

jQueryBridget("masonry", Masonry, $);
jQueryBridget("imagesLoaded", imagesLoaded, $);
jQueryBridget("done", imagesLoaded, $);

import Headroom from "headroom.js";

import barba from "@barba/core";
import Glide from "@glidejs/glide";
import Swiper, { EffectFade, Navigation, Scrollbar } from "swiper";

import Splide from "@splidejs/splide";

// Import the Cloudinary class.
import { Cloudinary } from "@cloudinary/url-gen";
import { trim } from "@cloudinary/url-gen/actions/videoEdit";
import { quality, format } from "@cloudinary/url-gen/actions/delivery";
import { fit } from "@cloudinary/url-gen/actions/resize";
import { auto as autoQuality } from "@cloudinary/url-gen/qualifiers/quality";
import { auto as autoFormat } from "@cloudinary/url-gen/qualifiers/format";
// import { Transformation } from "@cloudinary/url-gen";

// Create and configure your Cloudinary instance.
const cld = new Cloudinary({
	cloud: {
		cloudName: "unit-london",
	},
});

import { Tab, Collapse, initTE } from "tw-elements";

import { acfmap } from "./map";

import Cookies from "js-cookie";

import JSConfetti from "js-confetti";

const CryptoConvert = require("crypto-convert").default;

// Initialization for ES Users

import r from "./scripts/artwork-overlay";
import home from "./scripts/home";
import artistArchive from "./scripts/artist-archive";
import search from "./scripts/search";
import exhibitionCard from "./scripts/exhibition-card";
import splashHeading from "./scripts/splash-heading";
import openEnquire from "./scripts/enquire";
import openEnquireCatalog from "./scripts/enquire-catalog";
import voicesIntro from "./scripts/voices-hero";
import zoomNft from "./scripts/nft-overlay";
import sunNeverSets from "./scripts/sun-never-sets";

new SplitText("#logo a", {
	type: "chars",
});

function stopEvent(e) {
	e.preventDefault();
}

const convert = new CryptoConvert();

export function init() {
	if ($(window).width() < 992) {
		$("html").removeClass("dark");
	} else {
		setTimeout(function () {
			$("html").removeClass("dark");
		}, 500);
	}

	var myElement = document.querySelector("header");
	if (!$("body").hasClass("single-voice")) {
		var headroom = new Headroom(myElement);
		headroom.init();
	} else if (
		$("body").hasClass("single-voice") &&
		!$("body").hasClass("page-template-page-voices")
	) {
		let offset = 0;
		if ($(window).width() > 1024) {
			offset = 2500;
		}

		let options = {
			offset: offset,
			// onTop: function () {
			// 	$(myElement).addClass(
			// 		"voices:mix-blend-difference voices:text-white"
			// 	);
			// },
			// onNotTop: function () {
			// 	$(myElement).removeClass(
			// 		"voices:mix-blend-difference voices:text-white"
			// 	);
			// },
		};
		var headroom = new Headroom(myElement, options);
		headroom.init();
	} else {
		let options = {
			offset: 1000,
			onTop: function () {
				$(myElement).addClass("text-white");
			},
			onNotTop: function () {
				$(myElement).removeClass("text-white");
			},
		};
		var headroom = new Headroom(myElement, options);
		headroom.init();
	}

	if ($(window).width() < 992) {
		$(".h-screen").css("height", $(window).height());
		$(".min-h-screen").css("min-height", $(window).height());
	}

	if ($(".home").length) {
		// document.addEventListener("touchmove", stopEvent, { passive: false });
		home();
	}

	if ($("#hero").length) {
		hero();
	}

	// if ($(".single-voice").length) {
	// 	voicesHero();
	// }

	$("html").addClass("loaded-once");
	$("a[href*='sun-never-sets']").attr("data-barba-prevent", "self");
	$("a[href*='web3']").attr("data-barba-prevent", "self");

	if ($(".post-type-archive-artist").length) {
		artistArchive();
	}
	if ($(".archive-image").length) {
		archiveImage();
	}

	search();
	signupOverlay();
	menuOverlay();

	openEnquire();
	openEnquireCatalog();
	openEnquireNft();
	openMintNft();

	initTE({ Collapse, Tab });

	if ($("body").hasClass("about")) {
		about();
	}

	if ($(".postSlider").length) {
		postSlider();
	}

	if ($(".exhibitionCard").length) {
		exhibitionCard();
	}

	if ($(".archive.category, body.blog").length) {
		blogArchive();
	}

	if ($(".slider-full").length) {
		$(".slider-full").each(function () {
			let slider = $(this);

			var swiper = new Swiper(this, {
				modules: [Navigation, EffectFade],
				spaceBetween: 0,
				loop: true,
				autoHeight: true,
				effect: "fade",
				navigation: {
					nextEl: ".glide__arrow--right",
					prevEl: ".glide__arrow--left",
				},
			});

			let tl = gsap.timeline({
				scrollTrigger: {
					trigger: $(this),
					start: "top bottom",
					toggleActions: "play none none reset",
					// markers: true,
				},
			});

			if (slider.hasClass("natural-height")) {
				tl.to($(this).find(".swiper-slide.swiper-slide-active img"), {
					opacity: 1,
					// scale: 1,
					duration: 3,
					ease: "power1.out",
				}).set($(this).find(".swiper-slide img"), {
					opacity: 1,
					// scale: 1,
				});
			} else {
				tl.to($(this).find(".swiper-slide.swiper-slide-active img"), {
					opacity: 1,
					scale: 1,
					duration: 3,
					ease: "power1.out",
				}).set($(this).find(".swiper-slide img"), {
					opacity: 1,
					scale: 1,
				});
			}

			tl.fromTo(
				$(this).find("h3, p"),
				{
					opacity: 0,
				},
				{
					opacity: 1,
					duration: 0.5,
				},
				"-=.5"
			);
		});
	}

	if ($("#exhibitionArchive").length) {
		exhibitionArchive();
	}

	// if ($(".artworkCard").length) {
	r();
	// }

	if ($("#hero, #home-hero").length) {
		function headerColour() {
			if ($("#hero, #home-hero").length) {
				if (
					$(document).scrollTop() <
					$(window).height() -
						$(document).find("header").outerHeight() / 2
				) {
					$("header").css("color", "white");
					$("header").css("background-color", "transparent");
				} else {
					$("header").css("color", "");
					$("header").css("background-color", "");
				}
			} else {
				$("header").css("color", "");
				$("header").css("background-color", "");
			}
		}
		headerColour();
		$(window).on("scroll", function () {
			headerColour();
		});
	} else {
		// $(document).off("scroll", "body");

		$("header").css("color", "");
	}

	if ($(".heading-line").length) {
		headingLines();
	}

	async function logJSONData() {
		let response = await fetch("https://reallyfreegeoip.org/json/");
		let jsonData = await response.json();

		setTimeout(function () {
			console.log("7");
			$(document)
				.find('.hs_zip input[name="zip"]')
				.each(function () {
					$(this).val(jsonData.zip_code);
				});
		}, 7000);
	}
	logJSONData();

	if ($(".nav-section--dark").length) {
		ScrollTrigger.create({
			trigger: $(".nav-section--dark"),
			start: "top 50%",
			end: "max",
			onToggle: () => {
				$("html").toggleClass("dark");
			},
		});
	}

	if ($(".nft-card").length) {
		getFiveSecondClipsFromCloudinary();
	}

	if ($("#contactMainForm").length) {
		hbspt.forms.create({
			region: "na1",
			portalId: "5818574",
			formId: "ba942e33-d8b7-4a33-bac2-777e3b6c64ea",
			target: "#contactMainForm",
			onFormReady: () => {
				fetch("https://reallyfreegeoip.org/json/")
					.then((response) => response.json())
					.then((json) => {
						$("#contactMainForm")
							.find('.hs_zip input[name="zip"]')
							.val(json.zip_code);
						$("#contactMainForm")
							.find('.hs_ip_address input[name="ip_address"]')
							.val(json.ip);
					});
			},
		});
	}

	hbspt.forms.create({
		region: "na1",
		portalId: "5818574",
		formId: "f3515b32-508b-48a7-ae8f-61a51695869a",
		target: "#footerSignupForm",
		onFormSubmitted: function ($form) {
			setTimeout(() => {
				gsap.to(
					$(document).find("#footerSignupForm .submitted-message"),
					{ autoAlpha: 1 }
				);
			}, 1000);
		},
	});

	if ($("#signupBlockForm").length) {
		hbspt.forms.create({
			region: "na1",
			portalId: "5818574",
			formId: "c6ebd91e-161a-4c9c-ab72-30008156cc2b",
			// formId: "2e519e71-a07d-40ab-b2e7-bae85b87d383",
			target: "#signupBlockForm",
			formInstanceId: "1",
			onFormSubmitted: function ($form) {
				setTimeout(() => {
					gsap.to(
						$(document).find("#signupBlockForm .submitted-message"),
						{ autoAlpha: 1 }
					);
				}, 1000);
			},
		});
	}

	if ($("#signupOverlayForm").length) {
		hbspt.forms.create({
			region: "na1",
			portalId: "5818574",
			formId: "c6ebd91e-161a-4c9c-ab72-30008156cc2b",
			target: "#signupOverlayForm",
			formInstanceId: "2",
			onFormSubmitted: function ($form) {
				setTimeout(() => {
					gsap.to(
						$(document).find(
							"#signupOverlayForm .submitted-message"
						),
						{ autoAlpha: 1 }
					);
				}, 1000);
			},
		});
	}

	if ($(".nft-template-default").length) {
		hbspt.forms.create({
			region: "na1",
			portalId: "5818574",
			formId: "34b1a4cb-0e0d-4151-a399-c58c668ac573",
			version: "V2_PRERELEASE",
			target: ".mint-form",
			onFormReady: () => {
				fetch("https://reallyfreegeoip.org/json/")
					.then((response) => response.json())
					.then((json) => {
						$(".mint-form")
							.find('.hs_zip input[name="zip"]')
							.val(json.zip_code);
						$(".mint-form")
							.find('.hs_ip_address input[name="ip_address"]')
							.val(json.ip);
					});
			},
			onFormSubmit: function ($form) {
				gsap.timeline()
					.to(
						"#artworkMintForm-nft",
						{
							autoAlpha: 0,
						},
						0
					)
					.to(
						".terms-link",
						{
							autoAlpha: 0,
						},
						0
					)
					.set("#artworkMintContent-nft", {
						display: "none",
					})
					.to(
						".transaction",
						{
							autoAlpha: 1,
						},
						0
					);

				mintNFT();
			},
		});

		hbspt.forms.create({
			region: "na1",
			portalId: "5818574",
			formId: "2246d8cc-a72e-4f46-b268-47ed694b83d1",
			target: "#artworkMintForm-nft .fiat-form.hbspt-form",
			version: "V2_PRERELEASE",
			onFormReady: () => {
				fetch("https://reallyfreegeoip.org/json/")
					.then((response) => response.json())
					.then((json) => {
						$("#artworkMintForm-nft .fiat-form.hbspt-form")
							.find('.hs_zip input[name="zip"]')
							.val(json.zip_code);
						$("#artworkMintForm-nft .fiat-form.hbspt-form")
							.find('.hs_ip_address input[name="ip_address"]')
							.val(json.ip);
					});
			},
			onFormSubmit: function ($form) {
				gsap.timeline()
					.to(
						"#artworkMintForm-nft",
						{
							autoAlpha: 0,
						},
						0
					)
					.to(
						".terms-link",
						{
							autoAlpha: 0,
						},
						0
					)
					.set("#artworkMintContent-nft", {
						display: "none",
					})
					.to(
						".transaction",
						{
							autoAlpha: 1,
						},
						0
					);

				mintNFT();
			},
		});

		nftPageSize();
		function nftPageSize() {
			if ($(window).width() > 1023) {
				$("#artworkLoaderWrapper-nft").css(
					"padding",
					$("header").outerHeight() + "px 0px"
				);

				$(".artworkOverlayImage").css(
					"height",
					$(window).height() - $("header").outerHeight() * 2
				);
			} else {
				$(".artworkOverlayImage").css("height", "");
			}

			// if (
			// 	$(".artworkOverlayImage").height() >
			// 	$(".artworkOverlayContent").outerHeight()
			// ) {
			// 	ScrollTrigger.create({
			// 		// markers: true,
			// 		pin: ".artworkOverlayContent",
			// 	});
			// } else {
			// 	ScrollTrigger.refresh();
			// }
		}
		$(window).on("resize", nftPageSize);
	}

	if ($(".page-template-page-contact").length) {
		contact();
	}

	if ($(document).find(".signup-block--content").length) {
		signupBlock();
	}

	splashHeading();

	videoSection();

	if ($(".fixed-side").length) {
		fixed_side();
	}

	fullScreenImageReveal();

	if ($(".feature2x-panel").length) {
		featurePanels();
	}

	if ($(".splide").length) {
		sliderPanel();
	}

	if ($(".slider-panel").length) {
		sliderPanelOld();
	}

	if ($(".content-slider .swiper").length) {
		contentSlider();
	}

	if ($("body").hasClass("search-results")) {
		searchArchive();
	}

	if ($(document).find(".masonry").length) {
		masonry();
	} else {
		sideNavigation();
	}

	if ($(".fullScreenGallery").length) {
		fullScreenGallery();
	}

	mixedWidthSlider();
	mixedWidthSlider2();

	let featureAnimation = gsap.timeline().fromTo(
		".full-width-feature img",
		{
			yPercent: -10,
		},
		{
			yPercent: 10,
			ease: "none",
		}
	);

	$(".feature-section").each(function () {
		ScrollTrigger.create({
			animation: featureAnimation,
			trigger: $(this),
			start: "top bottom",
			// markers: true,
			scrub: 0.2,
		});
	});

	if (!$("body").hasClass("single-voice")) {
		setTimeout(function () {
			$(".video-section").each(function () {
				if (!$(".page-template-page-contact").length) {
					ScrollTrigger.create({
						trigger: $(this),
						start: "top-=200px top",
						end: "bottom+=200px bottom",
						// markers: true,
						onToggle: (self) => $("html").toggleClass("dark"),
					});
				}
				ScrollTrigger.create({
					trigger: $(this),
					start: "top top",
					end: "bottom+=50px 50px",
					// markers: true,
					onEnter: (self) => $("header").addClass("dark"),
					onEnterBack: (self) => $("header").addClass("dark"),
					onLeave: (self) => $("header").removeClass("dark"),
					onLeaveBack: (self) => $("header").removeClass("dark"),
				});
			});
		}, 1000);
	}
	artistSection();
	contentsSection();
	sectionCountdown();
	countdownBadges();

	requestCatalogue();

	artworkVideos();

	if ($("footer").length) {
		ScrollTrigger.create({
			trigger: $(".footer-spacer"),
			start: "top 60px",
			// end: "max",
			// markers: true,
			onToggle: () => {
				$("header").toggleClass("dark");
			},
		});
	}

	if ($(".load-more").length && $(".page-template-page-web3").length) {
	} else if (
		$(".load-more").length &&
		!$(".page-template-page-web3").length
	) {
		inifiteScroll();
	}

	if ($("body").hasClass("single-nft")) {
		zoomNft();
	}

	if ($(".artworkPrice").length) {
		cryptoConversion();
	}

	if ($(".get-cv-button").length) {
		let cv_button = $(".cv-button-area").html();
		$(".get-cv-button").append(cv_button);
	}

	if ($("#comments").length) {
		if (window.location.hash == "#comments") {
			gsap.to(window, {
				duration: 2,
				scrollTo: { y: "#comments", offsetY: 100 },
			});
		}
		if (window.location.hash == "#respond") {
			gsap.to(window, {
				duration: 2,
				scrollTo: { y: "#respond", offsetY: 100 },
			});
		}
	}

	if ($(".tabs").length) {
		tabs();
	}

	if ($("#chartdiv").length) {
		sunNeverSets();
	}

	ScrollTrigger.refresh();
}

// setTimeout(function () {
// 	ScrollTrigger.create({
// 		id: "ve-sidebar",
// 		trigger: "#top",
// 		start: "top top",
// 		endTrigger: ".next-section",
// 		end: "bottom bottom",
// 		pin: "#ve-sidebar",
// 		markers: true,
// 		refreshPriority: -1,
// 	});
// 	console.log("timed");
// }, 1000);

function hero() {
	if ($("html").hasClass("loaded-once")) {
		new SplitText("#heroTitle", {
			type: "words",
		});
		new SplitText("#heroTitle div", {
			type: "chars",
		});

		gsap.timeline({
			scrollTrigger: {
				trigger: "#hero",
				start: "top bottom",
				toggleActions: "play none none none",
				// markers: true,
			},
			defaults: {
				duration: 1,
			},
		})
			.fromTo(
				"#heroMask",
				{ opacity: 1 },
				{ opacity: 0.1, onComplete: () => heroScrolls() },
				0
			)
			.fromTo("#heroContent", { opacity: 0.1 }, { opacity: 1 }, 0.5)
			.addLabel("start")
			.fromTo("#heroIntro", { autoAlpha: 0 }, { autoAlpha: 1 })
			.fromTo(
				"#heroIntro h2 div div",
				{
					width: 0,
				},
				{
					width: "100%",
					duration: 2,
					ease: "power4.out",
				},
				"start-=.25"
			)
			.from(
				"#heroTitle div div",
				{ opacity: 0, y: "-100%", stagger: 0.02 },
				"start"
			)
			.fromTo(
				"#heroSubtitle",
				{
					opacity: 0,
				},
				{
					opacity: 1,
				},
				"start+=1"
			)
			.fromTo(
				"#heroBottom, #heroArrow",
				{
					opacity: 0,
				},
				{
					opacity: 1,
				},
				"start+=1.5"
			);

		if ($("#hero").find("video").length) {
			$("#hero").find("video")[0].play();
		}
	}
}
function heroScrolls() {
	gsap.timeline({
		scrollTrigger: {
			trigger: "#hero",
			start: "top top",
			end: "bottom top",
			scrub: true,
			// markers: true,
		},
	})
		.fromTo("#heroContent", { opacity: 1 }, { opacity: 0.25 }, 0)
		.fromTo("#heroMask", { opacity: 0.1 }, { opacity: 0.8 }, 0);

	gsap.timeline({
		scrollTrigger: {
			trigger: "#hero-wrap",
			start: "bottom top",
			onEnter: () => {
				$("#hero-wrap").addClass("opacity-0 invisible");
			},
			onEnterBack: () => {
				$("#hero-wrap").removeClass("opacity-0 invisible");
			},
			// markers: true,
		},
	});
}

function archiveImage() {
	$(document).on("mouseover", ".archive-post", function () {
		let thisPost = $(this);

		if (thisPost.find(".archive-image video").length) {
			gsap.to(thisPost.find(".archive-image video"), {
				autoAlpha: 1,
			});
			gsap.to(thisPost.find(".archive-image img"), {
				autoAlpha: 0,
				S: () => {
					thisPost.find(".archive-image video").get(0).play();
				},
			});
		}
	});

	$(document).on("mouseleave", ".archive-post", function () {
		let thisPost = $(this);

		if (thisPost.find(".archive-image video").length) {
			gsap.to(thisPost.find(".archive-image video"), {
				autoAlpha: 0,
			});
			gsap.to(thisPost.find(".archive-image img"), {
				autoAlpha: 1,
				onComplete: () => {
					thisPost.find(".archive-image video").get(0).pause();
					thisPost
						.find(".archive-image video")
						.get(0).currentTime = 0;
				},
			});
		}
	});
}

function fullScreenGallery() {
	let sliders = document.querySelectorAll(".fullScreenGallery");
	sliders.forEach((element) => {
		new Glide(element, { gap: 0 }).mount();
	});

	let tl = gsap.timeline({
		scrollTrigger: {
			trigger: ".fullScreenGallery",
			start: "top bottom",
			toggleActions: "play none none reset",
			// markers: true,
		},
	});

	if ($("body").hasClass("single-voice")) {
		tl.set(".fullScreenGallery img", { opacity: 1, scale: 1 });

		let voices = gsap.timeline().fromTo(
			".fullScreenGallery .glide__slide--active img",
			{
				yPercent: -10,
			},
			{
				yPercent: 10,
				ease: "none",
			}
		);

		$(".fullScreenGallery").each(function () {
			ScrollTrigger.create({
				animation: voices,
				trigger: $(this),
				start: "top bottom",
				end: "bottom top",
				// markers: true,
				scrub: 0.2,
			});
		});
	}

	tl.to(".fullScreenGallery img", {
		opacity: 1,
		scale: 1,
		duration: 3,
		ease: "power1.out",
	}).from(
		".fullScreenGallery h2 div div",
		{
			width: 0,
			duration: 1,
			ease: "power4.out",
		},
		0.75
	);
}

function signupOverlay() {
	let openSignup = gsap
		.timeline({
			paused: true,
			defaults: {
				duration: 0.3,
			},
		})
		.fromTo(".header-right, .header-menu", { y: 0 }, { y: -100 })
		.fromTo(
			"#signupOverlay",
			{ y: "-100%", autoAlpha: 0 },
			{ y: 0, autoAlpha: 1 },
			0
		)
		.fromTo(
			"#signupOverlayContent",
			{
				autoAlpha: 0,
				y: 70,
			},
			{
				autoAlpha: 1,
				y: 0,
				duration: 0.5,
			},
			"-=.1"
		)
		.fromTo(
			"#closeSignupOverlay",
			{ opacity: 0, x: "100%" },
			{ opacity: 1, x: "0%" },
			"-=1"
		);

	$(document).on("click", "#openSignupOverlay", function () {
		openSignup.timeScale(1).play();
		// $("#signupOverlay").addClass("fixed");
		$("header").addClass("pointer-events-none");
		// $("body, html").addClass("overflow-hidden h-screen");
	});
	$(document).on("click", "#closeSignupOverlay", function () {
		openSignup.timeScale(1.5).reverse();
		// $("#signupOverlay").removeClass("fixed");
		$("header").removeClass("pointer-events-none");
		// $("body, html").removeClass("overflow-hidden h-screen");
	});

	if (!Cookies.get("new_visitor") && !Cookies.get("hubspotutk")) {
		setTimeout(() => {
			ScrollTrigger.create({
				start: $(window).height() + 200,
				// markers: true,
				once: true,
				onEnter: () => {
					$(document).find("#openSignupOverlay").trigger("click");
					let suo = parseInt(
						$("#signupOverlay").attr("data-cookielength")
					);
					Cookies.set("new_visitor", true, { expires: suo });
				},
			});
		}, 3000);
	}

	$(document).one(
		"change",
		"#signupOverlay input[name='interests[]']",
		function () {
			gsap.timeline()
				.set("#signupOverlay .moreQuestions1", { display: "block" })
				.fromTo(
					"#signupOverlay .moreQuestions1",
					{ autoAlpha: 0 },
					{ autoAlpha: 1 }
				);
		}
	);

	$(document).one(
		"change",
		"#signupOverlay input[name='description']",
		function () {
			gsap.timeline()
				.set("#signupOverlay .moreQuestions2", { display: "block" })
				.fromTo(
					"#signupOverlay .moreQuestions2",
					{ autoAlpha: 0 },
					{ autoAlpha: 1 }
				);
		}
	);

	$(document).on("change", "input[name='newsletter']", function () {
		var $otherRadioButtons = $(
			"input[name^='newsletter'][value='" + this.value + "']"
		).not(this);
		$otherRadioButtons.prop("checked", $(this).prop("checked"));
	});
}

let openMenu = gsap
	.timeline({
		paused: true,
	})
	.fromTo(
		".header-right, .header-menu, #logo, #openMenuOverlay",
		{ y: 0 },
		{ y: -100 }
	)
	.set("#menuOverlay", { autoAlpha: 1 }, 0);

if ($(window).width() < 845) {
	openMenu.to("#menuOverlayInner", { y: 0 }, 0);
} else {
	openMenu.to("#menuOverlayInner", { x: 0 }, 0);
}

openMenu
	.fromTo("#closemenuOverlayBG", { autoAlpha: 0 }, { autoAlpha: 1 }, 0)
	.from("#menuOverlay ul#menu-main > li", {
		autoAlpha: 0,
		y: 50,
		stagger: 0.1,
	})
	.from("#closeMenuOverlay", { opacity: 0, x: "100%" }, "-=.5");

function menuOverlay() {
	$(document).on("click", "#openMenuOverlay", function () {
		openMenu.timeScale(1).play();
		// $("#menuOverlay").addClass("fixed");
		$("header").addClass("pointer-events-none");
		$("body, html").addClass("overflow-hidden");
	});
	$(document).on(
		"click",
		"#closeMenuOverlay, #closemenuOverlayBG",
		function () {
			openMenu.timeScale(1.5).reverse();
			// $("#menuOverlay").removeClass("fixed");
			$("header").removeClass("pointer-events-none");
			$("body, html").removeClass("overflow-hidden");
		}
	);

	// if ($(window).width() < 1024) {
	$(".menu-item-has-children > a").attr("data-barba-prevent", "self");

	// $(document)
	// 	.find(".menu-item-has-children a")
	// 	.off()
	// 	.on("click", function (e) {
	// 		e.preventDefault();

	// 		if (!$(this).hasClass("active")) {
	// 			// gsap.fromTo(
	// 			// 	$(this).next(".sub-menu"),
	// 			// 	{
	// 			// 		autoAlpha: 0,
	// 			// 	},
	// 			// 	{
	// 			// 		autoAlpha: 1,
	// 			// 	}
	// 			// );

	// 			$(this)
	// 				.addClass("active")
	// 				.next(".sub-menu")
	// 				.slideDown({
	// 					start: function () {
	// 						$(this).css({
	// 							display: "flex",
	// 						});
	// 					},
	// 				});
	// 		} else {
	// 			// gsap.fromTo(
	// 			// 	$(this).next(".sub-menu"),
	// 			// 	{
	// 			// 		autoAlpha: 1,
	// 			// 	},
	// 			// 	{
	// 			// 		autoAlpha: 0,
	// 			// 	}
	// 			// );

	// 			$(this)
	// 				.removeClass("active")
	// 				.next(".sub-menu")
	// 				.slideUp({
	// 					end: function () {
	// 						$(this).css({
	// 							display: "flex",
	// 						});
	// 					},
	// 				});
	// 		}
	// 	});

	if ($(window).width() > 844) {
		$(document).on("mouseenter", "#menu-main > li", function () {
			$(this).siblings().addClass("text-unit-dark");
		});
		$(document).on("mouseleave", "#menu-main > li", function () {
			$(this).siblings().removeClass("text-unit-dark");
		});
	}

	// } else {
	// 	$(document).on("mouseenter", ".menu-item-has-children", function () {
	// 		gsap.fromTo(
	// 			$(this).find(".sub-menu"),
	// 			{
	// 				autoAlpha: 0,
	// 			},
	// 			{
	// 				autoAlpha: 1,
	// 				duration: 1,
	// 			}
	// 		);
	// 	});

	// 	$(document).on("mouseleave", ".menu-item-has-children", function () {
	// 		gsap.fromTo(
	// 			$(this).find(".sub-menu"),
	// 			{
	// 				autoAlpha: 1,
	// 			},
	// 			{
	// 				autoAlpha: 0,
	// 				duration: 1,
	// 			}
	// 		);
	// 	});
	// }
}

$(document).on("click", "#voicesMenuOpen", function () {
	$("#openMenuOverlay").trigger("click");
});

// Draw out section heading lines

function headingLines() {
	$(document)
		.find(".heading-line")
		.each(function () {
			ScrollTrigger.create({
				trigger: $(this),
				start: "top bottom",
				onEnter: () => {
					gsap.fromTo(
						$(this).closest(".intro-heading"),
						{
							opacity: 0,
						},
						{
							opacity: 1,
						}
					);
					gsap.timeline()
						.fromTo(
							$(this),
							{
								width: "0%",
							},
							{
								width: "100%",
								duration: 2,
							}
						)
						.fromTo(
							$(this).closest(".intro-heading").next(),
							{
								autoAlpha: 0,
							},
							{
								autoAlpha: 1,
							}
						);
				},
				onLeave: () => {
					gsap.to($(this), {
						width: "0%",
					});
				},
				onLeaveBack: () => {
					gsap.to($(this), {
						width: "0%",
					});
				},
				onEnterBack: () => {
					gsap.fromTo(
						$(this),
						{
							width: "0%",
						},
						{
							width: "100%",
							duration: 2,
						}
					);
				},
			});
		});
}

function signupBlock() {
	gsap.fromTo(
		".signup-block--content",
		{
			y: 75,
			autoAlpha: 0,
		},
		{
			scrollTrigger: {
				trigger: "#signup-block",
				start: "top 55%",
				end: "top 100%",
				toggleActions: "play none none reverse",
				// markers: true,
				onEnter: () => $("body").addClass("dark"),
				onEnterBack: () => $("body").removeClass("dark"),
			},
			y: 0,
			autoAlpha: 1,
			duration: 1,
		}
	);

	// $(document).one(
	// 	"change",
	// 	"#signup-block input[name='interests[]']",
	// 	function () {
	// 		gsap.timeline()
	// 			.set("#signup-block .moreQuestions1", { display: "block" })
	// 			.fromTo(
	// 				"#signup-block .moreQuestions1",
	// 				{ autoAlpha: 0 },
	// 				{ autoAlpha: 1 }
	// 			);
	// 	}
	// );

	// $(document).one(
	// 	"change",
	// 	"#signup-block input[name='description']",
	// 	function () {
	// 		gsap.timeline()
	// 			.set("#signup-block .moreQuestions2", { display: "block" })
	// 			.fromTo(
	// 				"#signup-block .moreQuestions2",
	// 				{ autoAlpha: 0 },
	// 				{ autoAlpha: 1 }
	// 			);
	// 	}
	// );

	function ValidateEmail(inputText) {
		var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
		if (inputText.match(mailformat)) {
			gsap.timeline()
				.to("#signup-block .hs_email", { opacity: 0 })
				.set("#signup-block .hs_email", { display: "none" })
				.set(
					"#signup-block .hs_firstname, #signup-block .hs_lastname",
					{
						display: "block",
					}
				)
				.to("#signup-block .hs_firstname, #signup-block .hs_lastname", {
					opacity: 1,
				});

			return true;
		} else {
			// $("#signup-block .hs_email input").after(
			// 	'<span class="wpcf7-not-valid-tip new-tip block text-xs">You have entered an invalid email address</span>'
			// );
			// setTimeout(() => $(document).find(".new-tip").remove(), 8000);
			return false;
		}
	}

	setTimeout(function () {
		let email_field = $(document).find("#signup-block .hs_email input");

		email_field.on("focusout", function () {
			ValidateEmail(email_field.val());
		});
	}, 2000);
}

function sideNavigation() {
	// Section navigation
	if ($(".nav-section").length > 1) {
		if (!$("#feature_panel_section_nav").length) {
			$("main").append(
				'<div id="feature_panel_section_nav" class="w-full z-20 bg-white opacity-0 transition-colors fixed bottom-0 left-0 py-8 px-4 font-vheading text-xs text-black flex items-center justify-center ring-2 ring-gray-200 ring-opacity-30 lg:w-auto lg:bg-transparent dark:lg:bg-transparent lg:bottom-auto lg:top-1/2 lg:-translate-y-1/2  lg:px-0 lg:py-0 lg:rotate-90 lg:ring-0 lg:origin-left lg:text-black dark:lg:text-white overflow-x-scroll lg:overflow-visible voices:hidden"><ul class="absolute left-4  top-5 lg:-top-7 flex lg:justify-center items-center space-x-4 pr-4 lg:-translate-y-full lg:-translate-x-1/2 lg:relative"></ul></div>'
			);

			$(".nav-section[data-nav]:not([data-nav=''])").each(function (i) {
				if ($(this).attr("data-nav")) {
					let active = "";
					if (i == 0) active = "active";

					$(this).attr("data-section-index", i + 1);

					$(document)
						.find("#feature_panel_section_nav ul")
						.append(
							'<li class="" data-index="' +
								(i + 1) +
								'" class="' +
								active +
								'"><button class="w-max transition-colors block tracking-wide">' +
								$(this).attr("data-nav") +
								"</button></li>"
						);

					ScrollTrigger.create({
						trigger: $(this),
						start: "top 50%",
						end: "bottom 50%",
						onToggle: () => {
							$("#feature_panel_section_nav ul li").removeClass(
								"active"
							);
							$(
								"#feature_panel_section_nav ul li[data-index='" +
									$(this).attr("data-section-index") +
									"']"
							).addClass("active");
						},
					});
				}
			});

			if ($(document).find("#feature_panel_section_nav li").length == 0) {
				$(document).find("#feature_panel_section_nav").remove();
			}

			new SplitText("#feature_panel_section_nav button", {
				type: "chars",
				position: "absolute",
				reduceWhiteSpace: false,
				// charsClass: "transition-colors duration-75",
			});
			if (
				$(
					".fullScreenGallery, .full-bleed-hero-section, .large-format-text, .image-slider-full"
				).length
			) {
				setTimeout(function () {
					side_nav_color();
				}, 3000);
				function side_nav_color() {
					var section_pos0 = 0,
						section_pos_end0 = 0,
						section_pos1 = 0,
						section_pos_end1 = 0,
						section_pos2 = 0,
						section_pos_end2 = 0,
						section_pos3 = 0,
						section_pos_end3 = 0,
						section_pos4 = 0,
						section_pos_end4 = 0;

					$(
						".fullScreenGallery, .full-bleed-hero-section, .large-format-text, .image-slider-full"
					).each(function (index) {
						if (index == 0) {
							section_pos0 = $(this).offset().top;
							section_pos_end0 =
								$(this).offset().top + $(window).height();
						}

						if (index == 1) {
							section_pos1 = $(this).offset().top;
							section_pos_end1 =
								$(this).offset().top + $(window).height();
						}

						if (index == 2) {
							section_pos2 = $(this).offset().top;
							section_pos_end2 =
								$(this).offset().top + $(window).height();
						}

						if (index == 3) {
							section_pos3 = $(this).offset().top;
							section_pos_end3 =
								$(this).offset().top + $(window).height();
						}

						if (index == 4) {
							section_pos4 = $(this).offset().top;
							section_pos_end4 =
								$(this).offset().top + $(window).height();
						}
					});

					$("#feature_panel_section_nav li button div").each(
						function () {
							let letter_pos = $(this).offset().top + 5;

							if (letter_pos < section_pos0) {
								$(this).removeClass("text-white");
							} else if (
								letter_pos >= section_pos0 &&
								letter_pos < section_pos_end0
							) {
								$(this).addClass("text-white");
							} else if (
								letter_pos > section_pos_end0 &&
								letter_pos < section_pos1
							) {
								$(this).removeClass("text-white");
							} else if (
								letter_pos >= section_pos1 &&
								letter_pos < section_pos_end1
							) {
								$(this).addClass("text-white");
							} else if (
								letter_pos > section_pos_end1 &&
								letter_pos < section_pos2
							) {
								$(this).removeClass("text-white");
							} else if (
								letter_pos >= section_pos2 &&
								letter_pos < section_pos_end2
							) {
								$(this).addClass("text-white");
							} else if (
								letter_pos > section_pos_end2 &&
								letter_pos < section_pos3
							) {
								$(this).removeClass("text-white");
							} else if (
								letter_pos >= section_pos3 &&
								letter_pos < section_pos_end3
							) {
								$(this).addClass("text-white");
							} else if (
								letter_pos > section_pos_end3 &&
								letter_pos < section_pos4
							) {
								$(this).removeClass("text-white");
							} else if (
								letter_pos >= section_pos4 &&
								letter_pos < section_pos_end4
							) {
								$(this).addClass("text-white");
							} else if (letter_pos > section_pos_end4) {
								$(this).removeClass("text-white");
							}
						}
					);
				}
				$(window).on("scroll", side_nav_color);
			}

			$(document).on(
				"click",
				"#feature_panel_section_nav button",
				function () {
					$("#feature_panel_section_nav li").removeClass("active");
					$(this).closest("li").addClass("active");

					let section_index = $(this)
						.closest("li")
						.attr("data-index");

					gsap.to(window, {
						duration: 1,
						scrollTo: {
							y:
								".nav-section[data-section-index='" +
								section_index +
								"']",
							offsetY: 50,
						},
						ease: "power2.in",
					});
				}
			);

			gsap.fromTo(
				"#feature_panel_section_nav",
				{
					// autoAlpha: 0,
				},
				{
					// autoAlpha: 1,
					scrollTrigger: {
						trigger: "#main-content",
						start: "top top",
						end: "bottom bottom",
						// markers: true,
						toggleActions: "play none none none",
						onEnter: () => {
							gsap.timeline()
								.fromTo(
									"#feature_panel_section_nav",
									{
										autoAlpha: 0,
									},
									{
										autoAlpha: 1,
										duration: 0.15,
									}
								)
								.fromTo(
									"#feature_panel_section_nav ul li",
									{
										y: 100,
										autoAlpha: 0,
									},
									{
										y: 0,
										autoAlpha: 1,
										stagger: 0.1,
										duration: 0.25,
									}
								);
						},
						onEnterBack: () => {
							gsap.timeline()
								.fromTo(
									"#feature_panel_section_nav",
									{
										autoAlpha: 0,
									},
									{
										autoAlpha: 1,
										duration: 0.15,
									}
								)
								.fromTo(
									"#feature_panel_section_nav ul li",
									{
										y: 100,
										autoAlpha: 0,
									},
									{
										y: 0,
										autoAlpha: 1,
										stagger: 0.1,
										duration: 0.25,
									}
								);
						},
						onLeave: () => {
							gsap.timeline()
								.fromTo(
									"#feature_panel_section_nav ul li",
									{
										y: 0,
										autoAlpha: 1,
									},
									{
										y: 100,
										autoAlpha: 0,
										stagger: 0.1,
										duration: 0.25,
									}
								)
								.fromTo(
									"#feature_panel_section_nav",
									{
										autoAlpha: 1,
									},
									{
										autoAlpha: 0,
										duration: 0.15,
									}
								);
						},
						onLeaveBack: () => {
							gsap.timeline()
								.fromTo(
									"#feature_panel_section_nav ul li",
									{
										y: 0,
										autoAlpha: 1,
									},
									{
										y: 100,
										autoAlpha: 0,
										stagger: 0.1,
										duration: 0.25,
									}
								)
								.fromTo(
									"#feature_panel_section_nav",
									{
										autoAlpha: 1,
									},
									{
										autoAlpha: 0,
										duration: 0.15,
									}
								);
						},
					},
				}
			);
		}
	}
}

function about() {
	if ($("#about-slider").length) {
		new Glide("#about-slider", {
			perView: 4,
			gap: 80,
			bound: true,
			animationDuration: 1000,
			breakpoints: {
				1400: {
					perView: 3,
				},
				1024: {
					perView: 2,
					gap: 40,
				},
				600: {
					perView: 1,
				},
			},
		}).mount();
	}

	ScrollTrigger.create({
		trigger: $("#main-content"),
		start: "top-=50px top",
		endTrigger: $(".nav-section:nth-child(2)"),
		end: "top 50%",
		// markers: 1,
		onToggle: () => {
			$("header").toggleClass("text-black");
		},
	});
}

function postSlider() {
	let postSliders = document.querySelectorAll(".postSlider");
	postSliders.forEach((element) => {
		new Glide(element, {
			perView: 3,
			gap: 100,
			bound: true,
			animationDuration: 1000,
			breakpoints: {
				1400: {
					perView: 3,
					gap: 80,
				},
				1024: {
					perView: 2,
					gap: 40,
				},
				600: {
					perView: 1,
				},
			},
		}).mount();
	});
}

function contact() {
	gsap.fromTo(
		"#contactMain > div",
		{
			opacity: 0,
			y: 100,
		},
		{
			opacity: 1,
			y: 0,
			duration: 2,
		}
	);

	if ($(".acf-map").length) {
		acfmap();
	}
	setTimeout(function () {
		ScrollTrigger.refresh();
	}, 1000);
}

function exhibitionArchive() {
	ScrollTrigger.create({
		trigger: $("#exhibitionSide"),
		start: "top 32px",
		endTrigger: "#exhibitionArchive",
		end: "bottom bottom",
		// markers: true,
		pin: true,
	});

	$(document)
		.find("#exhibitionArchive a > div")
		.each(function () {
			gsap.fromTo(
				$(this),
				{
					autoAlpha: 0,
					x: "-30px",
				},
				{
					autoAlpha: 1,
					x: "0px",
					scrollTrigger: {
						trigger: $(this),
						start: "top 85%",
						toggleActions: "play reverse play reverse",
						// markers: true,
					},
				}
			);
		});

	function setSideExhibition(ex) {
		// let ex = $(this);

		let sideEx = {
			title: ex.find("[data-extitle]").data("extitle"),
			subtitle: ex.find("[data-exsubtitle]").data("exsubtitle"),
			image: ex.find("[data-eximage]").data("eximage"),
			date: ex.find("[data-exdate]").data("exdate"),
			link: ex.data("exlink"),
		};

		$(".exhibitionSideTemplate a").attr("href", sideEx.link);
		$(".exhibitionSideTemplateImage").attr("src", sideEx.image);
		$(".exhibitionSideTemplateTitle").html(sideEx.title);
		$(".exhibitionSideTemplateSubtitle").html(sideEx.subtitle);
		$(".exhibitionSideTemplateDate").html(sideEx.date);
	}

	setSideExhibition($(document).find("#exhibitionArchive a:first-child"));

	$(document).on("mouseenter", "#exhibitionArchive a", function () {
		// let index = $(this).attr("data-index");

		let ex = $(this);
		// setTimeout(setSideExhibition(ex), 500);

		gsap.timeline({
			defaults: {
				duration: 0.5,
				// overwrite: true,
			},
			onStart: () => {
				$("#exhibitionSide a").css("pointer-events", "none");
			},
			onComplete: () => {
				$("#exhibitionSide a").css("pointer-events", "");
			},
		})
			.to($(".exhibitionSideTemplate"), {
				autoAlpha: 0,
			})
			.call(setSideExhibition, [ex])
			.to($(".exhibitionSideTemplate"), { autoAlpha: 1, delay: 0.5 });
		// .fromTo(
		// 	$(".exhibitionSideTemplate img"),
		// 	{ scale: 1.2, opacity: 0 },
		// 	{ scale: 1, opacity: 1, ease: "power1.out", duration: 1 },
		// 	"-=.25"
		// );
	});

	// $("#archiveExhibitionsList .next.page-numbers").attr("data-barba-prevent");
	$(document).on(
		"click",
		"#archiveExhibitionsList .next.page-numbers",
		function (e) {
			e.preventDefault();
			let link = $(this).attr("href");

			let loadArea = $(document).find(".ex-load-area");

			loadArea.load(
				link + " #exhibitionArchive",
				function (response, status, xhr) {
					console.log("loadd");

					loadArea.find("#exhibitionArchive").contents().unwrap();
					loadArea.contents().unwrap();

					$(".pagination + .pagination").remove();

					$(document)
						.find("#exhibitionArchive a > div")
						.each(function () {
							gsap.fromTo(
								$(this),
								{
									autoAlpha: 0,
									x: "-30px",
								},
								{
									autoAlpha: 1,
									x: "0px",
									scrollTrigger: {
										trigger: $(this),
										start: "top 85%",
										toggleActions:
											"play reverse play reverse",
										// markers: true,
									},
								}
							);
						});

					ScrollTrigger.refresh();
				}
			);
		}
	);

	ScrollTrigger.create({
		trigger: $("#exhibitionArchive"),
		start: "bottom bottom",
		// markers: true,
		// pin: true,
		onEnter: () => {
			$(document)
				.find("#archiveExhibitionsList .next.page-numbers")
				.trigger("click");
		},
	});
}

// Panels

$(document).on("click", ".show-revealable-content", function () {
	// $(this).prev().hide();

	if (
		($(this).closest(".panel").hasClass("text-panel-section") &&
			!$("body").hasClass("single-voice")) ||
		$(this)
			.closest(".nav-section[data-nav='Overview']")
			.hasClass("read-more-below")
	) {
		$(this).hide();
		gsap.fromTo(
			$(this).next().show(),
			{
				autoAlpha: 0,
				// y: 50,
			},
			{
				autoAlpha: 1,
				// y: 0,
				onComplete: () => {
					$(document).on(
						"click",
						".hide-revealable-content",
						function () {
							gsap.fromTo(
								$(this).closest(".revealable-content"),
								{
									autoAlpha: 1,
								},
								{
									autoAlpha: 0,
									duration: 0.25,
									onComplete: () => {
										$(this)
											.closest(".revealable-content")
											.hide()
											.prev()
											.show();

										ScrollTrigger.refresh(true);
									},
								}
							);
						}
					);

					ScrollTrigger.refresh(true);
				},
			}
		);
	} else {
		let content = $(this).next().html();

		$("body").append(
			'<div class="rev-content z-40 opacity-0 invisible fixed inset-0 bg-black text-white bg-opacity-50"><div class="fixed [&>*]:text-base [&>*]:leading-6 translate-x-full w-[90%] lg:w-1/2 right-0 h-full overflow-y-auto ml-1/2 bg-black py-10 px-8 lg:py-20 lg:px-12 [&>.hide-revealable-content]:hidden"></div><button id="close-rev-content" class="font-heading absolute top-4 right-4 lg:top-8 lg:right-8 opacity-0">Close</button></div>'
		);

		$(document).find(".rev-content > div").append(content);

		let openRevCont = gsap
			.timeline()
			.fromTo(
				$(document).find(".rev-content"),
				{
					autoAlpha: 0,
				},
				{
					autoAlpha: 1,
				}
			)
			.fromTo(
				$(document).find(".rev-content > div"),
				{
					x: "100%",
				},
				{
					x: "0%",
					ease: "power1.easeIn",
				}
			)
			.fromTo(
				$(document).find(".rev-content > div > *"),
				{
					y: 30,
					autoAlpha: 0,
				},
				{
					y: 0,
					autoAlpha: 1,
					ease: "power1.easeIn",
				}
			)
			.fromTo(
				$(document).find("#close-rev-content"),
				{
					autoAlpha: 0,
				},
				{
					autoAlpha: 1,
					ease: "power1.easeIn",
				},
				"-=.25"
			);

		$(document).on(
			"click",
			"#close-rev-content, .rev-content",
			function () {
				openRevCont
					.reverse()
					.eventCallback("onReverseComplete", () =>
						$(document).find(".rev-content").remove()
					);
			}
		);
	}
});

function featurePanels() {
	$(".feature2x-panel").each(function () {
		let tl = gsap.timeline({
			scrollTrigger: {
				trigger: $(this),
				start: "top 50%",
				toggleActions: "play reverse play reverse",
			},
		});

		tl.to($(this).find("img"), {
			autoAlpha: 1,
		}).from($(this).find(".grid > div"), {
			autoAlpha: 0,
			y: 100,
		});
	});
}

function masonry() {
	let $grid = $(".masonry");

	$grid.each(function () {
		$(this).attr(
			"data-cards",
			$(this).find(".artwork-card, .nft-card").length
		);

		let origin =
			$("body").hasClass("single-voice") &&
			$(this).attr("data-cards") == 1
				? false
				: true;

		$(this).masonry({
			columnWidth: ".grid-sizer",
			percentPosition: true,
			itemSelector: ".masonry-item",
			gutter: ".gutter-sizer",
			transitionDuration: 0,
			initLayout: false,
			originLeft: origin,
		});
	});

	// $grid.find(".skeleton-block").remove();
	// setTimeout(() => {
	$grid.imagesLoaded().done(function (instance) {
		$(".loader").hide();

		$grid.masonry("layout");

		sideNavigation();
		gsap.set(".masonry", { opacity: 1 });

		inifiteScrollNFTs();

		let movement = 0;
		if ($("body").hasClass("single-voice")) {
			movement = 50;
		}

		ScrollTrigger.batch(".masonry-item", {
			// markers: true,
			onEnter: (elements, triggers) => {
				gsap.fromTo(
					elements,
					{ opacity: 0, y: movement },
					{ opacity: 1, y: 0, stagger: 0.15, ease: "power1.out" }
				);
			},
			onEnterBack: (elements, triggers) => {
				gsap.fromTo(
					elements,
					{ opacity: 0, y: -movement },
					{ opacity: 1, y: 0, stagger: 0.15, ease: "power1.out" }
				);
			},
			onLeave: (elements, triggers) => {
				gsap.to(
					elements,

					{ opacity: 0 }
				);
			},
			onLeaveBack: (elements, triggers) => {
				gsap.to(elements, { opacity: 0 });
			},
		});

		setTimeout(function () {
			$grid.masonry("layout");
			ScrollTrigger.refresh();
		}, 4000);
	});
	// }, 10000);
}

if ($(document).find(".full-bleed-hero").length) {
	$(".full-bleed-hero").each(function () {
		gsap.fromTo(
			$(this).find("img"),
			{
				opacity: 0,
				scale: 1.3,
			},
			{
				scrollTrigger: {
					trigger: $(this),
					start: "top 85%",
					toggleActions: "play reverse play reverse",
					// markers: true,
				},
				opacity: 1,
				scale: 1,
				duration: 1,
			}
		);
	});
}

function sliderPanel() {
	var elms = document.getElementsByClassName("splide");

	for (var i = 0; i < elms.length; i++) {
		let splide = new Splide(elms[i], {
			type: "slide",
			autoWidth: true,
			rewind: true,
			gap: "80px",
			speed: 500,
			pagination: true,
			breakpoints: {
				1440: {
					gap: "50px",
				},
				1024: {
					gap: "25px",
				},
				600: {
					gap: "15px",
					autoWidth: false,
					padding: 25,
				},
			},
		});

		let slider = $(elms[i]);

		splide.on("mounted", function () {
			gsap.set(slider.find(".splide__pagination li"), {
				x: 48,
			});

			slider
				.find(".splide__pagination__page.is-active")
				.closest("li")
				.addClass("active-li")
				.siblings()
				.removeClass("active-li");

			slider
				.find(".splide__pagination li.active-li")
				.prevAll()
				.each(function (index) {
					$(this).attr("data-index", index * -1 - 1);
				});

			slider
				.find(".splide__pagination li.active-li")
				.nextAll()
				.each(function (index) {
					$(this).attr("data-index", index + 1);
				});
		});

		splide.mount();

		splide.on("updated", function () {
			slider
				.find(".splide__pagination__page.is-active")
				.closest("li")
				.addClass("active-li")
				.siblings()
				.removeClass("active-li");

			gsap.set(slider.find(".splide__pagination li"), {
				x: slider.find(".active-li").index() * -16 + 48,
			});

			slider
				.find(".splide__pagination li.active-li")
				.prevAll()
				.each(function (index) {
					$(this).attr("data-index", index * -1 - 1);
				});

			slider
				.find(".splide__pagination li.active-li")
				.nextAll()
				.each(function (index) {
					$(this).attr("data-index", index + 1);
				});

			gsap.to(slider.find(".splide__pagination li button"), {
				scale: 0.65,
			});

			gsap.to(slider.find(".active-li").next().find("button"), {
				scale: 1,
			});
			gsap.to(slider.find(".active-li").next().next().find("button"), {
				scale: 1,
			});
			gsap.to(slider.find(".active-li").find("button"), {
				scale: 1,
			});
			gsap.to(slider.find(".active-li").prev().find("button"), {
				scale: 1,
			});
			gsap.to(slider.find(".active-li").prev().prev().find("button"), {
				scale: 1,
			});
		});

		splide.on("dragged", function () {
			gsap.to(slider.find(".splide__pagination li"), {
				x:
					"+=" +
					$(".splide__pagination__page.is-active")
						.closest("li")
						.attr("data-index") *
						-16,
			});

			$(".splide__pagination__page.is-active")
				.closest("li")
				.addClass("active-li")
				.siblings()
				.removeClass("active-li");

			slider
				.find(".splide__pagination li.active-li")
				.prevAll()
				.each(function (index) {
					$(this).attr("data-index", index * -1 - 1);
				});

			slider
				.find(".splide__pagination li.active-li")
				.nextAll()
				.each(function (index) {
					$(this).attr("data-index", index + 1);
				});

			$(".splide__pagination__page.is-active")
				.closest("li")
				.attr("data-index", 0);

			gsap.to(slider.find(".splide__pagination li button"), {
				scale: 0.65,
			});

			gsap.to(
				$(".splide__pagination__page.is-active")
					.closest("li")
					.next()
					.find("button"),
				{
					scale: 1,
				}
			);
			gsap.to(
				$(".splide__pagination__page.is-active")
					.closest("li")
					.next()
					.next()
					.find("button"),
				{
					scale: 1,
				}
			);
			gsap.to(
				$(".splide__pagination__page.is-active")
					.closest("li")
					.find("button"),
				{
					scale: 1,
				}
			);
			gsap.to(
				$(".splide__pagination__page.is-active")
					.closest("li")
					.prev()
					.find("button"),
				{
					scale: 1,
				}
			);
			gsap.to(
				$(".splide__pagination__page.is-active")
					.closest("li")
					.prev()
					.prev()
					.find("button"),
				{
					scale: 1,
				}
			);
		});

		slider.on("click", ".splide__pagination__page", function () {
			$(this)
				.closest("li")
				.addClass("active-li")
				.siblings()
				.removeClass("active-li");

			gsap.to(slider.find(".splide__pagination li"), {
				x: "+=" + $(this).parent().attr("data-index") * -16,
			});

			slider
				.find(".splide__pagination li.active-li")
				.prevAll()
				.each(function (index) {
					$(this).attr("data-index", index * -1 - 1);
				});

			slider
				.find(".splide__pagination li.active-li")
				.nextAll()
				.each(function (index) {
					$(this).attr("data-index", index + 1);
				});

			$(this).closest("li").attr("data-index", 0);

			gsap.to(slider.find(".splide__pagination li button"), {
				scale: 0.65,
			});

			gsap.to($(this).closest("li").next().find("button"), {
				scale: 1,
			});
			gsap.to($(this).closest("li").next().next().find("button"), {
				scale: 1,
			});
			gsap.to($(this).closest("li").find("button"), {
				scale: 1,
			});
			gsap.to($(this).closest("li").prev().find("button"), {
				scale: 1,
			});
			gsap.to($(this).closest("li").prev().prev().find("button"), {
				scale: 1,
			});
		});

		splide.on("move", function () {
			$(document)
				.find(".splide__pagination li button")
				.css("pointer-events", "none");
		});

		splide.on("moved", function () {
			$(document)
				.find(".splide__pagination li button")
				.css("pointer-events", "");
		});
	}

	$(document).on(
		"click",
		".artwork-slider-section .splide__slide, .images-slider-section .splide__slide",
		function () {
			let thisIndex = $(this).index();

			$(this).closest(".panel").addClass("current-slider");

			$(this)
				.closest("ul")
				.find("li")
				.each(function () {
					$(this).find("img").attr("data-src");
					$("#images-slider-overlay .glide__slides").append(
						'<li class="glide__slide"><img class="mx-auto h-full w-full object-contain" src="' +
							$(this).find("img").attr("data-src") +
							'"></li>'
					);
				});

			new Glide("#images-slider-overlay .glide", {
				perView: 1,
				gap: "80px",
				bound: true,
				startAt: thisIndex,
				animationDuration: 1000,
				breakpoints: {
					1440: {
						gap: "50px",
					},
					1024: {
						gap: "25px",
					},
					600: {
						gap: "15px",
					},
				},
			}).mount();

			gsap.timeline()
				.fromTo(
					"#images-slider-overlay",
					{ autoAlpha: 0 },
					{ autoAlpha: 1 }
				)
				.fromTo(
					"#images-slider-overlay .glide",
					{ autoAlpha: 0, y: 100 },
					{ autoAlpha: 1, y: 0 },
					"-=.1"
				)
				.fromTo(
					"#close-images-slider-overlay",
					{ autoAlpha: 0, x: "100%" },
					{ autoAlpha: 1, x: "0%" },
					"-=.1"
				);
		}
	);

	$(document).on("click", "#close-images-slider-overlay", function () {
		let current_slide =
			$("#images-slider-overlay").find(".glide__slide--active").index() +
			1;

		$(document)
			.find(
				".current-slider .splide__pagination li:nth-child(" +
					current_slide +
					") button"
			)
			.trigger("click");

		$(document).find("#images-slider-overlay .glide__slides").empty();

		$(document).find(".current-slider").removeClass("current-slider");

		gsap.timeline()
			.fromTo(
				"#images-slider-overlay",
				{ autoAlpha: 1 },
				{ autoAlpha: 0 },
				0
			)
			.fromTo(
				"#close-images-slider-overlay",
				{ autoAlpha: 1, x: "0%" },
				{ autoAlpha: 0, x: "100%" },
				0
			);
	});
}

function sliderPanelOld() {
	$(".slider-panel").each(function () {
		let numOfSlides = $(this).find(".glide__list").children("li");
		new Glide(this, {
			perView: 3,
			gap: "80px",
			bound: true,
			animationDuration: 1000,
			breakpoints: {
				1440: {
					perView: 3,
					gap: "80px",
				},
				1024: {
					perView: 2,
					gap: "80px",
				},
				600: {
					perView: 1,
					gap: "15px",
				},
			},
		}).mount();
	});
}

if ($(".records-grid-section").length) {
	$(".records-grid-section .grid > div").each(function () {
		gsap.fromTo(
			$(this),
			{
				// y: 75,
				autoAlpha: 0,
			},
			{
				scrollTrigger: {
					trigger: $(this),
					start: "top 85%",
					toggleActions: "play reverse play reverse",
					// markers: true,
				},
				// y: 0,
				autoAlpha: 1,
				duration: 1,
			}
		);
	});
}

function openEnquireNft() {
	let openEnquire = gsap
		.timeline({
			paused: true,
		})
		.fromTo(
			"#artworkEnquireOverlay-nft",
			{ autoAlpha: 0 },
			{ autoAlpha: 1 }
		)

		.addLabel("faded")
		.fromTo(
			"#artworkEnquireContent-nft",
			{ x: "-100%" },
			{
				x: 0,
				onComplete: () => {
					$("#logo").addClass("text-white");
				},
				onReverseComplete: () => {
					$("#logo").removeClass("text-white");
				},
			},
			"faded"
		)

		// .set("#logo", { color: "white" }, "faded+=.25")
		.fromTo(
			"#artworkEnquireContent-nft > div",
			{ y: 100, autoAlpha: 0 },
			{ y: 0, autoAlpha: 1, duration: 1, ease: "power1.out" },
			"faded+=.25"
		);

	$(document)
		// .off()
		.on("click", ".artworkEnquire-nft", function (e) {
			e.stopPropagation();

			let form_title =
				$(this).attr("data-form-title") ?? "How can we help?";
			$("#artworkEnquireForm-nft > h3").html(form_title);

			$("#artworkEnquireContent-nft").removeClass("hide-offer");
			if ($(this).hasClass("artworkEnquire-nft-enquire")) {
				$("#artworkEnquireContent-nft").addClass("hide-offer");
			}

			$("#artworkEnquireForm-nft #formArtworkContent").empty();

			// setTimeout(function () {
			$(document).find("#formArtwork").remove();
			$(document)
				.find("#artworkEnquireContent-nft .hs_message")
				.append(
					'<div id="formArtwork" class="flex items-center space-x-4 mt-4 pb-4 border-b border-white border-opacity-[.15]"><div id="formArtworkImage"><img /></div><div id="formArtworkContent"></div></div>'
				);
			// }, 3000);

			openEnquire.timeScale(1).play();

			let artist,
				title,
				image,
				year,
				info,
				catalog,
				catalogSub,
				imageEmail;

			if ($(this).parents("#artworkLoaderWrapper-nft").length) {
				artist = $(document)
					.find("#artworkLoaderWrapper-nft .artworkArtist")
					.text();
				title = $(document)
					.find("#artworkLoaderWrapper-nft .artworkTitle")
					.text();
				image = $(document)
					.find("#artworkLoaderWrapper-nft img")
					.attr("data-thumb");
				imageEmail = $(document)
					.find("#artworkLoaderWrapper-nft img")
					.attr("data-thumbemail");
				year = $(document)
					.find("#artworkLoaderWrapper-nft .artworkYear")
					.text();
				info = "";
			} else {
				artist = $(this)
					.closest(".artworkCard-nft")
					.attr("data-artist");
				title = $(this)
					.closest(".artworkCard-nft")
					.find(".artworkCardTitle")
					.text();
				image = $(this).closest(".artworkCard-nft").attr("data-image");
				imageEmail = $(this)
					.closest(".artworkCard-nft")
					.attr("data-imageemail");
				year = $(this)
					.closest(".artworkCard-nft")
					.find(".artworkCardYear")
					.text();
				info = "";
			}

			$('#artworkEnquireForm-nft input[name="subject"]').val(
				artist + " - " + title
			);

			$("#artworkEnquireForm-nft").attr("data-artist", artist);

			setTimeout(function () {
				setInterval(function () {
					$(
						"#artworkEnquireForm-nft .hs_artist_tags_2022__cloned_ input[type='checkbox']"
					).prop("checked", false);

					$(
						"#artworkEnquireForm-nft .hs_artist_tags_2022__cloned_ input[type='checkbox'][value='" +
							artist +
							"']"
					).prop("checked", true);

					artist = artist.trim();

					$(
						"#artworkEnquireForm-nft .hs-last_web3_enquiry_artwork_title input[type='hidden']"
					).val(artist + ", " + title + ", " + year);

					$(
						"#artworkEnquireForm-nft .hs-last_web3_enquiry_artist_name input[type='hidden']"
					).val(artist);

					$(
						"#artworkEnquireForm-nft .hs-last_web3_enquiry_artwork_name input[type='hidden']"
					).val(title);

					$(
						"#artworkEnquireForm-nft .hs-last_web3_enquiry_artwork_medium input[type='hidden']"
					).val("Digital");

					$(
						"#artworkEnquireForm-nft .hs-last_web3_enquiry_artwork_size input[type='hidden']"
					).val("0 x 0");

					$(
						"#artworkEnquireForm-nft .hs_last_web3_enquiry_artwork_image input[type='hidden']"
					).val(imageEmail);
				}, 3000);
			}, 3000);

			$('#artworkEnquireForm-nft input[name="artist"]').val(artist);
			$('#artworkEnquireForm-nft input[name="catalog"]').val(catalog);

			$("#artworkEnquireForm-nft #formArtworkImage img").attr(
				"src",
				image
			);
			$("#artworkEnquireForm-nft #formArtworkContent").append(
				"<div>" +
					artist +
					"</div><div>" +
					title +
					"</div><div>" +
					year +
					"</div><div>" +
					info +
					"</div>"
			);
		});

	$(document).on(
		"click",
		"#closeArtworkEnquireBG-nft, #closeArtworkEnquire-nft",
		function () {
			openEnquire.timeScale(1.5).reverse();
			setTimeout(function () {
				$("#artworkEnquireForm-nft #formArtworkContent").empty().show();
				$("#artworkEnquireForm-nft #formArtworkImage img").attr(
					"src",
					""
				);

				$(document).find("#artworkEnquireForm-nft .hbspt-form").empty();

				hbspt.forms.create({
					region: "na1",
					portalId: "5818574",
					formId: "dd925e82-ddcb-474b-9d2e-17ec6b639937",
					target: "#artworkEnquireForm-nft .hbspt-form", // Add the element class where you need to create the form again
					onFormReady: () => {
						fetch("https://reallyfreegeoip.org/json/")
							.then((response) => response.json())
							.then((json) => {
								$("#artworkEnquireForm-nft .hbspt-form")
									.find('.hs_zip input[name="zip"]')
									.val(json.zip_code);
								$("#artworkEnquireForm-nft .hbspt-form")
									.find(
										'.hs_ip_address input[name="ip_address"]'
									)
									.val(json.ip);
							});
					},
					onFormSubmit: function ($form) {
						jQuery(document)
							.find("#artworkEnquireForm-nft")
							.find(
								"input[type='checkbox'][value='" +
									jQuery("#artworkEnquireForm-nft").attr(
										"data-artist"
									) +
									"']"
							)
							.prop("checked", true)
							.change();

						setTimeout(() => {
							$(document)
								.find("#closeArtworkEnquire-nft")
								.trigger("click");
						}, 3000);
					},
				});
			}, 1000);
		}
	);

	$(document).one(
		"change",
		"#artworkEnquireForm-nft input[name='interests[]']",
		function () {
			gsap.timeline()
				.set("#artworkEnquireForm-nft .moreQuestions1", {
					display: "block",
				})
				.fromTo(
					"#artworkEnquireForm-nft .moreQuestions1",
					{ autoAlpha: 0 },
					{ autoAlpha: 1 }
				);
		}
	);

	$(document).one(
		"change",
		"#artworkEnquireForm-nft input[name='description']",
		function () {
			gsap.timeline()
				.set("#artworkEnquireForm-nft .moreQuestions2", {
					display: "block",
				})
				.fromTo(
					"#artworkEnquireForm-nft .moreQuestions2",
					{ autoAlpha: 0 },
					{ autoAlpha: 1 }
				);
		}
	);
}

function openMintNft() {
	let openMint = gsap
		.timeline({
			paused: true,
		})
		.fromTo("#artworkMintOverlay-nft", { autoAlpha: 0 }, { autoAlpha: 1 })

		.addLabel("faded")
		.fromTo(
			"#artworkMintContent-nft",
			{ x: "-100%" },
			{
				x: 0,
				onComplete: () => {
					$("#logo").addClass("text-white");
				},
				onReverseComplete: () => {
					$("#logo").removeClass("text-white");
				},
			},
			"faded"
		)

		// .set("#logo", { color: "white" }, "faded+=.25")
		.fromTo(
			"#artworkMintContent-nft > div",
			{ y: 100, autoAlpha: 0 },
			{ y: 0, autoAlpha: 1, duration: 1, ease: "power1.out" },
			"faded+=.25"
		)
		.fromTo(
			".terms-link",
			{
				autoAlpha: 0,
			},
			{
				autoAlpha: 1,
			}
		);

	$(document)
		// .off()
		.on("click", ".artworkMint-nft", function (e) {
			e.stopPropagation();

			// onNFTMintOverlay();

			$("#artworkMintForm-nft #formArtworkContent").empty();

			// setTimeout(function () {
			$(document).find("#formArtwork").remove();
			$(document)
				.find("#artworkMintContent-nft .hs_email")
				.before(
					'<div id="formArtwork" class="flex items-center space-x-4"><div id="formArtworkImage" class="w-32 h-auto"><img /></div><div id="formArtworkContent"></div></div>'
				);
			// }, 3000);

			$(document)
				.find("#artworkMintContent-nft .hs_main_wallet_address input")
				.prop("readonly", true);

			openMint.timeScale(1).play();

			let artist, title, image, year, info, catalog, catalogSub;

			if ($(this).parents("#artworkLoaderWrapper-nft").length) {
				artist = $(document)
					.find(
						"#artworkLoaderWrapper-nft .glide__slide--active .artworkArtist"
					)
					.text();
				title = $(document)
					.find(
						"#artworkLoaderWrapper-nft .glide__slide--active .artworkTitle"
					)
					.text();
				image = $(document)
					.find("#artworkLoaderWrapper-nft .glide__slide--active img")
					.attr("data-thumb");
				year = $(document)
					.find(
						"#artworkLoaderWrapper-nft .glide__slide--active .artworkYear"
					)
					.text();
				info = $(document)
					.find(
						"#artworkLoaderWrapper-nft .glide__slide--active .artworkPriceEth"
					)
					.text();
			} else {
				artist = $(this)
					.closest(".artworkCard-nft")
					.attr("data-artist");
				title = $(this)
					.closest(".artworkCard-nft")
					.find(".artworkCardTitle")
					.text();
				image = $(this).closest(".artworkCard-nft").attr("data-image");
				year = $(this)
					.closest(".artworkCard-nft")
					.find(".artworkCardYear")
					.text();
				info = "";
			}

			// $('#artworkMintForm-nft input[name="subject"]').val(
			// 	artist + " - " + title
			// );

			$("#artworkMintForm-nft").attr("data-artist", artist);

			// setTimeout(function () {
			artist = artist.trim();

			$(
				"#artworkMintForm-nft .hs_artist_tags_2022__cloned_ input[type='hidden']"
			).val(artist);

			$(
				"#artworkMintForm-nft .hs_last_web3_enquiry_artwork_title input[type='hidden']"
			).val(title);
			// }, 2000);

			$('#artworkMintForm-nft input[name="artist"]').val(artist);
			$('#artworkMintForm-nft input[name="catalog"]').val(catalog);

			$("#artworkMintForm-nft #formArtworkImage img").attr("src", image);
			$("#artworkMintForm-nft #formArtworkContent").append(
				"<div class='text-base uppercase font-bold mb-3'>" +
					artist +
					"</div><div class='text-base mb-2'>" +
					title +
					"</div><div class='text-base mb-2'>" +
					year +
					"</div><div class='text-base mb-2 font-semibold'>" +
					info +
					"</div>"
			);
		});

	$(document).on("click", ".mint-toggle", function () {
		$(".mint-toggle").removeClass("active");
		$(this).addClass("active");
		let form = $(this).attr("data-form");
		$(".mint-forms > div").hide();
		$("." + form).show();
	});

	$(document).on(
		"click",
		"#closeArtworkMintBG-nft, #closeArtworkMint-nft",
		function () {
			openMint.timeScale(2).reverse();
			setTimeout(function () {
				$("#artworkMintForm-nft #formArtworkContent").empty().show();
				$("#artworkMintForm-nft #formArtworkImage img").attr("src", "");
				$(document).find("#artworkMintForm-nft .hbspt-form").empty();

				// mint form
				hbspt.forms.create({
					region: "na1",
					portalId: "5818574",
					formId: "34b1a4cb-0e0d-4151-a399-c58c668ac573",
					target: "#artworkMintForm-nft .mint-form.hbspt-form",
					version: "V2_PRERELEASE",
					onFormReady: () => {
						fetch("https://reallyfreegeoip.org/json/")
							.then((response) => response.json())
							.then((json) => {
								$("#artworkMintForm-nft .mint-form.hbspt-form")
									.find('.hs_zip input[name="zip"]')
									.val(json.zip_code);
								$("#artworkMintForm-nft .mint-form.hbspt-form")
									.find(
										'.hs_ip_address input[name="ip_address"]'
									)
									.val(json.ip);
							});
					},
					onFormSubmit: function ($form) {
						gsap.timeline()
							.to(
								"#artworkMintForm-nft",
								{
									autoAlpha: 0,
								},
								0
							)
							.to(
								".terms-link",
								{
									autoAlpha: 0,
								},
								0
							)
							.set("#artworkMintContent-nft", {
								display: "none",
							})
							.to(
								".transaction",
								{
									autoAlpha: 1,
								},
								0
							);

						mintNFT();
					},
				});

				//fiat form
				hbspt.forms.create({
					region: "na1",
					portalId: "5818574",
					formId: "2246d8cc-a72e-4f46-b268-47ed694b83d1",
					target: "#artworkMintForm-nft .fiat-form.hbspt-form",
					version: "V2_PRERELEASE",
					onFormReady: () => {
						fetch("https://reallyfreegeoip.org/json/")
							.then((response) => response.json())
							.then((json) => {
								$("#artworkMintForm-nft .fiat-form.hbspt-form")
									.find('.hs_zip input[name="zip"]')
									.val(json.zip_code);
								$("#artworkMintForm-nft .fiat-form.hbspt-form")
									.find(
										'.hs_ip_address input[name="ip_address"]'
									)
									.val(json.ip);
							});
					},
					onFormSubmit: function ($form) {
						gsap.timeline()
							.to(
								"#artworkMintForm-nft",
								{
									autoAlpha: 0,
								},
								0
							)
							.to(
								".terms-link",
								{
									autoAlpha: 0,
								},
								0
							)
							.set("#artworkMintContent-nft", {
								display: "none",
							})
							.to(
								".transaction",
								{
									autoAlpha: 1,
								},
								0
							);

						mintNFT();
					},
				});

				// reset process
				gsap.set("#artworkMintContent-nft", {
					autoAlpha: 1,
					display: "block",
				});
				gsap.set(".transaction", { autoAlpha: 0 });
				$(".mint-form").hide();
				$(".show-mint, .show-fiat").removeClass("active");

				$(".show-mint p").html(
					"Connect your existing wallet to continue with the purchase of this NFT."
				);
				$(".show-fiat p").html(
					"Create a digital wallet linked to your Unit account and checkout with debit/credt card."
				);
			}, 1000);
		}
	);

	$(".showcon").on("click", showNftCongratulations);
}

function showNftCongratulations() {
	const canvas = document.getElementById("custom_canvas");
	const jsConfetti = new JSConfetti({ canvas });

	gsap.timeline().fromTo(
		"#nft-congratulations",
		{ autoAlpha: 0 },
		{
			autoAlpha: 1,
			onComplete: () => {
				jsConfetti.addConfetti({
					confettiRadius: 6,
					confettiNumber: 500,
				});
				setTimeout(function () {
					hideNftCongratulations();
				}, 4000);
			},
		}
	);

	$("#close-nft-congratulations").on("click", function () {
		hideNftCongratulations();
	});

	function hideNftCongratulations() {
		$("#closeArtworkMintBG-nft").trigger("click");
		gsap.fromTo("#nft-congratulations", { autoAlpha: 1 }, { autoAlpha: 0 });
	}
}

function showNftFailed() {
	gsap.timeline().fromTo("#nft-failed", { autoAlpha: 0 }, { autoAlpha: 1 });
	$("#close-nft-failed").on("click", function () {
		hideNftFailed();
	});

	function hideNftFailed() {
		$("#closeArtworkMintBG-nft").trigger("click");
		gsap.fromTo("#nft-failed", { autoAlpha: 1 }, { autoAlpha: 0 });
	}
}

function enableFiat() {
	if ($(".show-fiat").prop("disabled")) {
		$(".show-fiat").prop("disabled", false);
	} else {
		$(".show-fiat").prop("disabled", true);
	}
}

window.showNftCongratulations = showNftCongratulations;
window.showNftFailed = showNftFailed;
window.enableFiat = enableFiat;

function blogArchive() {
	$(".blogPost").each(function () {
		ScrollTrigger.create({
			trigger: $(this),
			start: "top bottom",
			onEnter: () => {
				gsap.fromTo(
					$(this),
					{
						y: 50,
						opacity: 0,
					},
					{
						y: 0,
						opacity: 1,
						duration: 1,
						ease: "power1.out",
					}
				);
			},
			onLeave: () => {
				gsap.to($(this), {
					opacity: 0,
				});
			},
			onLeaveBack: () => {
				gsap.to($(this), {
					opacity: 0,
				});
			},
			onEnterBack: () => {
				gsap.fromTo(
					$(this),
					{
						y: -50,
						opacity: 0,
					},
					{
						y: 0,
						opacity: 1,
						duration: 1,
						ease: "power1.out",
					}
				);
			},
		});
	});
}

function searchArchive() {
	$("#searchLoad .col-span-1").each(function () {
		ScrollTrigger.create({
			trigger: $(this),
			start: "top bottom",
			onEnter: () => {
				gsap.fromTo(
					$(this),
					{
						y: 50,
						opacity: 0,
					},
					{
						y: 0,
						opacity: 1,
						duration: 1,
						ease: "power1.out",
					}
				);
			},
			onLeave: () => {
				gsap.to($(this), {
					opacity: 0,
				});
			},
			onLeaveBack: () => {
				gsap.to($(this), {
					opacity: 0,
				});
			},
			onEnterBack: () => {
				gsap.fromTo(
					$(this),
					{
						y: -50,
						opacity: 0,
					},
					{
						y: 0,
						opacity: 1,
						duration: 1,
						ease: "power1.out",
					}
				);
			},
		});
	});
}

$(document).on("click", ".hs-fieldtype-checkbox > label span", function () {
	let form = $(this).closest("form");
	form.find(".hs-fieldtype-checkbox").removeClass("active");
	$(this).closest(".hs-fieldtype-checkbox").addClass("active");
	form.find(".hs-fieldtype-checkbox:not(.active) .input").hide();
	$(this).closest(".hs-fieldtype-checkbox").find(".input").fadeToggle();
});

$(document).on("click", function (e) {
	if (
		$(e.target).is(".hs-fieldtype-checkbox") ||
		$(e.target).is(".hs-fieldtype-checkbox > label") ||
		$(e.target).is(".hs-fieldtype-checkbox > label span") ||
		$(e.target).is(".hs-fieldtype-checkbox li") ||
		$(e.target).is(".hs-fieldtype-checkbox li span") ||
		$(e.target).is(".hs-fieldtype-checkbox input") ||
		$(e.target).is(".hs-fieldtype-checkbox ul")
	) {
		// console.log(e.target);
		// console.log("yes");
		// console.log(e.target.checked);
	} else {
		// console.log(e.target);
		// console.log("no");
		// console.log(e.target.checked);
		$(".hs-fieldtype-checkbox.active .input").hide();
		$(".hs-fieldtype-checkbox.active").removeClass("active");
	}
});

$(document).on("click", ".hs-fieldtype-select > label span", function () {
	$(".hs-fieldtype-select").removeClass("active");
	$(this).closest(".hs-fieldtype-select").addClass("active");
	$(".hs-fieldtype-select:not(.active) .input").hide();
	$(this).closest(".hs-fieldtype-select").find(".input").fadeToggle();
});

$(document).on("click", function (e) {
	if (
		$(e.target).is(".hs-fieldtype-select") ||
		$(e.target).is(".hs-fieldtype-select > label") ||
		$(e.target).is(".hs-fieldtype-select > label span") ||
		$(e.target).is(".hs-fieldtype-select select") ||
		$(e.target).is(".hs-fieldtype-select .input")
	) {
		// console.log(e.target);
		// console.log("yes");
	} else {
		// console.log(e.target);
		// console.log("no");
		$(".hs-fieldtype-select.active .input").hide();
		$(".hs-fieldtype-select.active").removeClass("active");
	}
});

$(document).on(
	"click",
	'#signupOverlayForm .hs_what_interests_u_ input[type="checkbox"]',
	function () {
		$(document)
			.find("#signupOverlayForm")
			.find(
				".hs_current___new__what_best_describes_u___cloned_, .hs_do_you_work_in_the_arts_"
			)
			.slideDown();
	}
);

function videoSection() {
	// make bg black when scrolling into a video section
	if ($(document).find(".feature-section").length) {
		setTimeout(function () {
			$(document)
				.find(".feature-section")
				.each(function () {
					if ($(this).find("iframe").length) {
						ScrollTrigger.create({
							trigger: $(this),
							start: "top 50%",
							end: "bottom 50%",
							// markers: true,
							onToggle: () => {
								$("html").toggleClass("dark");
							},
						});
					}
				});
		}, 3000);
	}
}

function requestCatalogue() {
	if (
		$(document).find(".request-catalogue-section, .feature-section").length
	) {
		$(document)
			.find(".request-catalogue-section .block, .feature-section .block")
			.each(function () {
				gsap.to($(this).find("img"), {
					scale: 1,
					opacity: 1,
					duration: 2,
					scrollTrigger: {
						trigger: $(this).find(".imgWrap"),
						start: "top 80%",
						// markers: true,
						toggleActions: "play reverse play reverse",
					},
				});
			});
	}
}

$(document).on("click", ".artworkShare", function () {
	let openShare = gsap
		.timeline({ paused: true })
		.fromTo(
			$(this).next(),
			{
				autoAlpha: 0,
			},
			{
				autoAlpha: 1,
			}
		)
		.fromTo(
			$(this).next().find(".social_links_item"),
			{
				autoAlpha: 0,
			},
			{
				autoAlpha: 1,
				stagger: 0.1,
			},
			"-=.5"
		);
	if ($(this).hasClass("active")) {
		openShare.reverse();
	} else {
		openShare.play();
	}
	$(this).toggleClass("active");
});

barba.hooks.afterLeave(() => {
	document.removeEventListener("touchmove", stopEvent, { passive: false });
	let triggers = ScrollTrigger.getAll();
	triggers.forEach((trigger) => {
		trigger.kill();
	});
});

barba.hooks.before(() => {
	document.querySelector("html").classList.add("is-transitioning");
	barba.wrapper.classList.add("is-animating");
});

barba.hooks.enter(() => {});

barba.hooks.after(() => {
	gsap.to($(document).find("#page-transition"), {
		autoAlpha: 0,
		onComplete: () => {
			$(document).find("#page-transition").remove();
		},
	});

	$("#menuOverlay").fadeOut(200);
	document.querySelector("html").classList.remove("is-transitioning");
	barba.wrapper.classList.remove("is-animating");
	$("html").addClass("dark").removeClass("overflow-hidden h-screen");
	$("header").removeClass("dark");
	// $("#menuOverlay").removeClass("fixed").css("transform", "");
	$("header").removeClass("pointer-events-none");
	$("body, html").removeClass("overflow-hidden h-screen");

	// setTimeout(function () {

	openMenu.timeScale(5).reverse();

	$(document)
		.find(".menu-item-has-children a")
		.removeClass("active")
		.next(".sub-menu")
		.slideUp({
			end: function () {
				$(".sub-menu").css({
					display: "flex",
				});
			},
		});
	// }, 3000);

	var _hsq = (window._hsq = window._hsq || []);
	_hsq.push(["setPath", window.location.pathname]);
	_hsq.push(["trackPageView"]);

	// console.log(window.location.pathname);

	// ScrollTrigger.refresh();
	if ($("body").hasClass("single-voice")) {
		voicesIntro();
	} else {
		init();
	}
	$("#menuOverlay").fadeIn();
});

// scroll to the top of the page
barba.hooks.enter((data) => {
	const navs = $(data.next.html).find("#menu-main > li");

	$("#menu-main > li").each(function (index) {
		const newClasses = $(navs[index]).get(0).classList.value;
		$(this).attr("class", newClasses);
	});

	window.scrollTo(0, 0);
});

barba.hooks.afterEnter(() => {
	if ($("body").hasClass("single-nft") && $(".rImage video").length) {
		var vids = document.querySelectorAll("video");
		vids.forEach((vid) => {
			var playPromise = vid.play();
			if (playPromise !== undefined) {
				playPromise.then((_) => {}).catch((error) => {});
			}
		});
	}
});

function firstLoad() {
	new SplitText("#heroTitle", {
		type: "words",
	});
	new SplitText("#heroTitle div", {
		type: "chars",
	});

	let introAnimation = gsap.timeline({
		paused: true,
		scrollTrigger: {
			trigger: "main",
			start: "top bottom",
			toggleActions: "play none none none",
			// markers: true,
		},
		defaults: {
			duration: 0.75,
		},
	});

	init();

	introAnimation
		.addLabel("start", "+=0.5")
		.fromTo(
			"header > *",
			{
				y: "-100px",
				autoAlpha: 0,
			},
			{
				y: "0px",
				autoAlpha: 1,
				onStart: () => {
					$("#logo svg").addClass("animate-pulse");
				},
				onComplete: () => {
					setTimeout(function () {
						$("#logo svg").removeClass("animate-pulse");
					}, 6000);
				},
			},
			"start"
		)

		// .to(
		// 	"#logo",
		// 	{
		// 		x: "-10px",
		// 		autoAlpha: 0,
		// 		stagger: -0.05,
		// 	},
		// 	2
		// )
		// .fromTo(
		// 	"#logo a",
		// 	{
		// 		scale: 0.65,
		// 	},
		// 	{
		// 		scale: 0.72,
		// 		onComplete: () => {
		// 			$("#logo a").addClass("show-dot");
		// 		},
		// 	}
		// )

		.fromTo(
			"#heroMask",
			{ opacity: 1 },
			{ opacity: 0, onComplete: () => heroScrolls() },
			"start"
		)
		.fromTo("#heroContent", { opacity: 0 }, { opacity: 1 }, "start")
		.fromTo("#heroIntro", { autoAlpha: 0 }, { autoAlpha: 1 }, "start+=.25")
		.fromTo(
			"#heroIntro h2 div div",
			{
				width: 0,
			},
			{
				width: "100%",
				duration: 1,
				ease: "power4.out",
			},
			"start+=.5"
		)
		.from(
			"#heroTitle div div",
			{
				opacity: 0,
				y: "-100%",
				stagger: 0.02,
			},
			"start+=.5"
		)
		.fromTo(
			"#heroSubtitle",
			{
				opacity: 0,
			},
			{
				opacity: 1,
			},
			"start+=1.5"
		)
		.fromTo(
			"#heroBottom, #heroArrow",
			{
				opacity: 0,
			},
			{
				opacity: 1,
			},
			"start+=2"
		);

	return introAnimation;
}

// barba.init({
// 	// prevent: ({ el }) => {
// 	// 	if ($(window).width() < 1024) {
// 	// 		console.log(el.parentElement.classList);
// 	// 		el.parentElement.classList &&
// 	// 			el.parentElement.classList.contains("menu-item-has-children");
// 	// 	}
// 	// },
// 	transitions: [
// 		{
// 			once(data) {
// 				// $("html").addClass("loaded-once");
// 				return firstLoad();
// 			},
// 		},
// 		{
// 			async leave() {
// 				await loaderIn();
// 			},
// 			enter() {
// 				loaderAway();
// 			},
// 		},
// 	],
// });

barba.init({
	requestError: (trigger, action, url, response) => {
		console.log(trigger);
		console.log(action);
		console.log(url);
		console.log(response);
	},
	timeout: 12000,
	transitions: [
		{
			once(data) {
				// $("html").addClass("loaded-once");

				let startAnimation = $("body").hasClass("single-voice")
					? voicesIntro()
					: firstLoad();

				return startAnimation;
			},
		},
		{
			name: "opacity-transition",
			leave(data) {
				$("body").append(
					'<div id="page-transition" class="z-50 fixed inset-0 bg-black invisible opacity-0"></div>'
				);
				return gsap
					.timeline()
					.to($(document).find("#page-transition"), {
						autoAlpha: 1,
						duration: 0.7,
					})
					.to(data.current.container, {
						opacity: 0,
						duration: 0.2,
						onComplete: () => $("html").addClass("dark"),
					});
			},
			enter(data) {
				return gsap.from(data.next.container, {
					opacity: 0,
					duration: 0.2,
				});
			},
		},
	],
});

// // add GA pageview

// barba.hooks.after(() => {
// 	ga("set", "page", window.location.pathname);
// 	ga("send", "pageview");
// });

// // update body classes

barba.hooks.afterLeave((data) => {
	// Set <body> classes for "next" page
	var nextHtml = data.next.html;
	var response = nextHtml.replace(
		/(<\/?)body( .+?)?>/gi,
		"$1notbody$2>",
		nextHtml
	);
	var bodyClasses = $(response).filter("notbody").attr("class");
	$("body").attr("class", bodyClasses);

	$(".current_page_item").removeClass("current_page_item");
});

function inifiteScroll() {
	$(document).on("click", ".load-more", function () {
		let page = $(this).attr("data-page");
		var url = new URL(window.location.href);
		let new_url =
			url.origin +
			url.pathname +
			"/page/" +
			page +
			"/?" +
			url.searchParams;

		$(".event-loading-area").load(
			new_url + " #searchLoad .grid",
			function (response, status, xhr) {
				if (status == "error") {
					var msg = "Sorry but there was an error: ";
					$("#error").html(msg + xhr.status + " " + xhr.statusText);
				}

				$(".event-loading-area .grid .blogPost").addClass("new-loaded");
				let new_posts = $(".event-loading-area .grid").html();
				$("#searchLoad .grid").append(new_posts);
				$(".event-loading-area").empty();

				ScrollTrigger.batch(".blogPost.new-loaded", {
					start: "top bottom",
					// markers: true,
					onEnter: (elements, triggers) => {
						gsap.fromTo(
							elements,
							{
								y: 50,
								opacity: 0,
							},
							{
								y: 0,
								opacity: 1,
								duration: 1,
								ease: "power1.out",
							}
						);
					},
					onLeave: (elements, triggers) => {
						gsap.to(elements, {
							opacity: 0,
						});
					},
					onLeaveBack: (elements, triggers) => {
						gsap.to(elements, {
							opacity: 0,
						});
					},
					onEnterBack: (elements, triggers) => {
						gsap.fromTo(
							elements,
							{
								y: -50,
								opacity: 0,
							},
							{
								y: 0,
								opacity: 1,
								duration: 1,
								ease: "power1.out",
							}
						);
					},
				});

				// ScrollTrigger.refresh();

				$(".blogPost.new-loaded").removeClass("new-loaded");

				page++;

				// Check if there is a next page
				$.get(
					url.origin +
						url.pathname +
						"/page/" +
						page +
						"/?" +
						url.searchParams
				)
					.done(function () {
						// exists code
						$(".load-more").attr("data-page", page);
						ScrollTrigger.refresh();
					})
					.fail(function () {
						// not exists code
						$(".load-more").remove();
					});

				setTimeout(() => {
					gsap.to(".loading__popup", { autoAlpha: 0, y: "100%" });
				}, 500);
			}
		);
	});

	ScrollTrigger.create({
		trigger: ".load-more",
		start: "top 80%",
		markers: false,
		onEnter: ({ progress, direction, isActive }) => {
			if ($(".load-more").length) {
				$(".load-more").trigger("click");
				gsap.to(".loading__popup", { autoAlpha: 1, y: 0 });
			}
		},
	});
}

function inifiteScrollNFTs() {
	$(document)
		.find(".nft-cascade-section .page-numbers")
		.attr("data-barba-prevent", "");

	$(document).on("click", ".nft-cascade-section .page-numbers", function (e) {
		e.preventDefault();

		let paginationNumber = parseInt($(this).html());

		gsap.timeline()
			.to(window, {
				scrollTo: { y: ".nft-cascade-section", offsetY: 150 },
			})
			.to("#searchLoad .masonry", {
				autoAlpha: 0,
				onComplete: () => {
					$("#searchLoad").empty();
					loadnewnfts();
				},
			})
			.set(".loader", { display: "block" })
			.to(".loader", { autoAlpha: 1 });

		var new_url = $(this).attr("href");

		function loadnewnfts() {
			$("#searchLoad").load(
				new_url + " #searchLoad > *",
				function (response, status, xhr) {
					if (status == "error") {
						var msg = "Sorry but there was an error: ";
						// $("#error").html(msg + xhr.status + " " + xhr.statusText);
						console.log(msg + xhr.status + " " + xhr.statusText);
					}

					let $grid = $("#searchLoad .masonry");

					$grid.masonry({
						columnWidth: ".grid-sizer",
						percentPosition: true,
						itemSelector: ".masonry-item",
						gutter: ".gutter-sizer",
						transitionDuration: 0,
						initLayout: false,
						originLeft: origin,
					});

					$grid.imagesLoaded().done(function (instance) {
						function checkIfAllVideosLoaded() {
							// Get all the video elements on the page.
							var videos = document.querySelectorAll(
								"#searchLoad .masonry video"
							);

							// console.table(videos);

							// Initialize a counter to keep track of how many videos have loaded.
							var loadedVideos = 0;

							// Loop through the videos and check if they are loaded.
							for (var i = 0; i < videos.length; i++) {
								if (videos[i].readyState === 4) {
									// The video is loaded, so increment the counter.
									loadedVideos++;
								}
							}

							console.log(loadedVideos);

							// If all the videos are loaded, return true.
							if (loadedVideos === videos.length) {
								onLoaded();
								// return true;
							} else {
								// Not all the videos are loaded, so return false.
								console.log(false);
								setTimeout(checkIfAllVideosLoaded, 1000);
								// return false;
							}
						}

						// checkIfAllVideosLoaded();

						getFiveSecondClipsFromCloudinary();

						onLoaded();

						function onLoaded(event) {
							// console.log("test");
							$grid.masonry("layout");

							gsap.timeline()
								.to(".loader", { autoAlpha: 0 })
								.set(".loader", { display: "none" })
								.set($grid, { autoAlpha: 1 })
								.to($grid.find(".masonry-item"), {
									autoAlpha: 1,
									stagger: 0.1,
								});
						}
					});

					$(document)
						.find(".nft-cascade-section .page-numbers")
						.attr("data-barba-prevent", "");

					const nextURL = new_url;
					const nextTitle =
						paginationNumber != 1
							? "Web3 - Unit - Page " + paginationNumber
							: "Web3 - Unit";
					const nextState = {
						additionalInformation: "Updated the URL with JS",
					};

					window.history.pushState(nextState, nextTitle, nextURL);
				}
			);
		}
	});
}

function cryptoConversion() {
	function numberWithCommas(x) {
		return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	}

	(async function () {
		await convert.ready().then(() => {
			$(".artworkPrice[data-eth]").each(function () {
				let eth = $(this).attr("data-eth");
				$(this)
					.find(".artworkPriceDollar")
					.html(
						"($" +
							numberWithCommas(convert.ETH.USD(eth).toFixed(2)) +
							")"
					);
			});
		});
	})();
}

function tabs() {
	$(document).on("click", ".tabs-name", function () {
		$(this).siblings().removeClass("active");
		$(this).addClass("active");
		$(this).closest(".tabs").find(".tab").addClass("hidden");
		$(this)
			.closest(".tabs")
			.find("#" + $(this).attr("data-tab"))
			.removeClass("hidden");
		ScrollTrigger.refresh();
	});
}

$(document).on("click", ".custom-select-title", function () {
	$(this).next().fadeToggle();

	$(document).on("click", function (event) {
		if ($(event.target).closest(".custom-select").length === 0) {
			$(document).find(".custom-select-options").hide();
		}
	});
});

$(document).on("click", "#current_order li button", function () {
	$(document).find(".custom-select-options").hide();

	$(document)
		.find("#current_order .custom-select-title span")
		.html($(this).html());

	const direction = $(this).attr("data-select");

	$("#currentExhibitionsList")
		.css("height", $("#currentExhibitionsList").height())
		.empty()
		.load(
			"/exhibitions/?" + direction + " #currentExhibitionsList",
			function (response, status, xhr) {
				exhibitionCard();
				ScrollTrigger.refresh();
				$(document)
					.find(" #currentExhibitionsList #currentExhibitionsList")
					.unwrap();
				if (status == "error") {
					var msg = "Sorry but there was an error: ";
					$("#currentExhibitionsList").html(
						msg + xhr.status + " " + xhr.statusText
					);
				}
			}
		);
});

$(document).on("click", "#upcoming_order li button", function () {
	$(document).find(".custom-select-options").hide();

	$(document)
		.find("#upcoming_order .custom-select-title span")
		.html($(this).html());

	const direction = $(this).attr("data-select");

	$("#upcomingExhibitionsList")
		.css("height", $("#upcomingExhibitionsList").height())
		.empty()
		.load(
			"/exhibitions/?" + direction + " #upcomingExhibitionsList",
			function (response, status, xhr) {
				exhibitionCard();
				ScrollTrigger.refresh();
				$(document)
					.find(" #upcomingExhibitionsList #upcomingExhibitionsList")
					.unwrap();
				if (status == "error") {
					var msg = "Sorry but there was an error: ";
					$("#upcomingExhibitionsList").html(
						msg + xhr.status + " " + xhr.statusText
					);
				}
			}
		);
});

$(document).on("click", "#online_order li button", function () {
	$(document).find(".custom-select-options").hide();

	$(document)
		.find("#online_order .custom-select-title span")
		.html($(this).html());

	const direction = $(this).attr("data-select");

	$("#onlineExhibitionsList")
		.css("height", $("#onlineExhibitionsList").height())
		.empty()
		.load(
			"/exhibitions/?" + direction + " #onlineExhibitionsList",
			function (response, status, xhr) {
				exhibitionCard();
				ScrollTrigger.refresh();
				$(document)
					.find(" #onlineExhibitionsList #onlineExhibitionsList")
					.unwrap();
				if (status == "error") {
					var msg = "Sorry but there was an error: ";
					$("#onlineExhibitionsList").html(
						msg + xhr.status + " " + xhr.statusText
					);
				}
			}
		);
});

$(document).on("click", "#archive_order li button", function () {
	$(document).find(".custom-select-options").hide();

	$(document)
		.find("#archive_order .custom-select-title span")
		.html($(this).html());

	const direction = $(this).attr("data-select");

	$("#archiveExhibitionsList")
		.css("height", $("#archiveExhibitionsList").height())
		.empty()
		.load(
			"/exhibitions/?" + direction + " #archiveExhibitionsList",
			function (response, status, xhr) {
				exhibitionArchive();
				ScrollTrigger.refresh();
				$(document)
					.find(" #archiveExhibitionsList #archiveExhibitionsList")
					.unwrap();
				if (status == "error") {
					var msg = "Sorry but there was an error: ";
					$("#archiveExhibitionsList").html(
						msg + xhr.status + " " + xhr.statusText
					);
				}
			}
		);
});

// pb v2 sections

function fixed_side() {
	$(".fixed-side").each(function () {
		let mm = gsap.matchMedia();
		mm.add("(min-width: 1024px)", () => {
			ScrollTrigger.create({
				trigger: $(this),
				start:
					"top center-=" +
					$(this).find(".fixed-side-pin-inner").outerHeight() / 2 +
					64,
				end:
					"bottom center+=" +
					$(this).find(".fixed-side-pin-inner").outerHeight() / 2 +
					64,
				pin: $(this).find(".fixed-side-pin"),
				// markers: true,
				pinSpacing: false,
				anticipatePin: 1,
			});
		});
	});
	ScrollTrigger.batch($(".fixed-side-gallery img"), {
		onEnter: (elements) => {
			gsap.to(elements, { opacity: 1, stagger: 0.15, delay: 0.15 });
		},
		onLeaveBack: (elements) => {
			gsap.to(elements, { opacity: 0 });
		},
	});
}

function mixedWidthSlider() {
	$(".swiper-auto-width").each(function () {
		let thisSwiper = $(this);

		let swiper = new Swiper(this, {
			modules: [Scrollbar],
			slidesPerView: "auto",
			loop: false,
			spaceBetween: 16,
			grabCursor: true,
			mousewheel: true,
			threshold: 5,
			touchEventsTarget: "container",
			breakpoints: {
				1024: {
					spaceBetween: 32,
				},
			},
			scrollbar: {
				el: ".swiper-scrollbar",
				draggable: true,
			},
			// on: {
			// 	beforeInit: () => {
			// 		thisSwiper.find(".swiper-slide").each(function () {
			// 			console.log(imgWidth);
			// 			let imgWidth = $(this).find("img").width();
			// 			$(this).css("max-width", imgWidth);
			// 			// $(this).css("width", imgWidth);
			// 		});
			// 	},
			// },
		});

		swiper.on("init", function () {});
	});
}

function mixedWidthSlider2() {
	$(".swiper-auto-width2").each(function () {
		let thisSwiper = $(this);

		let swiper = new Swiper(this, {
			modules: [Scrollbar],
			slidesPerView: "1.1",
			loop: false,
			spaceBetween: 16,
			grabCursor: true,
			mousewheel: true,
			threshold: 5,
			touchEventsTarget: "container",
			breakpoints: {
				1024: {
					slidesPerView: "2.25",
					spaceBetween: 32,
				},
			},
			scrollbar: {
				el: ".swiper-scrollbar",
				draggable: true,
			},
			// on: {
			// 	beforeInit: () => {
			// 		thisSwiper.find(".swiper-slide").each(function () {
			// 			console.log(imgWidth);
			// 			let imgWidth = $(this).find("img").width();
			// 			$(this).css("max-width", imgWidth);
			// 			// $(this).css("width", imgWidth);
			// 		});
			// 	},
			// },
		});

		swiper.on("init", function () {});
	});
}

function contentSlider() {
	$(".content-slider .swiper").each(function () {
		let thisSlider = this;

		function imgHeights() {
			let heights = [];

			$(thisSlider)
				.find("img")
				.each(function () {
					heights.push(Math.floor($(this).height()));
				});

			let tallestImg = Math.max(...heights);
			$(thisSlider).find(".image-wrapper").css("height", tallestImg);
		}

		new Swiper(this, {
			on: {
				init: imgHeights,
				resize: imgHeights,
			},
			modules: [Scrollbar],
			slidesPerView: 1.1,
			spaceBetween: 16,
			grabCursor: true,
			mousewheel: true,
			threshold: 5,
			touchEventsTarget: "container",
			breakpoints: {
				480: {
					slidesPerView: 2.1,
				},
				1024: {
					slidesPerView: 3.1,
					spaceBetween: 32,
				},
			},
			scrollbar: {
				el: ".swiper-scrollbar",
				draggable: true,
			},
		});
	});
}

function fullScreenImageReveal() {
	if ($(".large-format-text").length) {
		$(".large-format-text").each(function () {
			// new SplitText($(this).find("figure h2, figure blockquote"), {
			// 	type: "lines, words",
			// 	linesClass: "line",
			// });

			let lft = gsap.timeline({
				scrollTrigger: {
					trigger: $(this),
					// markers: true,
					start: "top 70%",
					end: "bottom 30%",
					// toggleActions: "play none none reverse",
					once: true,
				},
			});

			if (!$(".single-voice").length) {
				gsap.set($(this).find("img"), { scale: 1 });

				lft.fromTo(
					$(this).find(".large-format-text-inner"),
					{
						y: "30vh",
					},
					{
						y: "0vh",
						duration: 0.3,
					}
				)
					.addLabel("start")
					.fromTo(
						$(this).find(".full-screen-image-wipe"),
						{
							y: "0vh",
						},
						{
							y: "-100%",
							duration: 0.5,
							ease: "Power2.inOut",
						},
						"start"
					)
					.fromTo(
						$(this).find("img"),
						{
							scale: 1.3,
						},
						{
							scale: 1,
							duration: 2.5,
							ease: "Power2.out",
						},
						"start"
					)
					.fromTo(
						$(this).find(".line"),
						{
							y: 60,
							autoAlpha: 0,
						},
						{
							y: 0,
							autoAlpha: 1,
							stagger: 0.11,
							duration: 0.3,
							ease: "Power2.out",
						},
						"start+=" + 0.6
					);
			} else {
				gsap.set($(this).find(".large-format-text-inner"), { y: 0 });
				$(this).find(".full-screen-image-wipe").remove();

				// let paralax = gsap.fromTo(
				// 	$(this).find("img"),
				// 	{
				// 		yPercent: -10,
				// 	},
				// 	{
				// 		yPercent: 10,
				// 		ease: "none",
				// 	}
				// );

				// ScrollTrigger.create({
				// 	animation: paralax,
				// 	trigger: $(this),
				// 	start: "top bottom",
				// 	scrub: 0.2,
				// 	// markers: true,
				// });
			}

			lft.fromTo(
				$(this).find(".line"),
				{
					y: 30,
					autoAlpha: 0,
				},
				{
					y: 0,
					autoAlpha: 1,
					stagger: 0.1,
					delay: 0.25,
				}
			);
		});
	}
}

$(document).on("click", ".js-videoPoster", function (ev) {
	ev.preventDefault();
	var $wrapper = $(this).closest(".js-videoWrapper");
	$(this).prev().addClass("js-videoIframe");
	gsap.to($wrapper.find(".videoPoster"), { autoAlpha: 0 });
});

$(document)
	.find(".triggerSignupButton")
	.on("click", () => $("#openSignupOverlay").trigger("click"));

// horizontal artist
function artistSection() {
	function imgHeight() {
		$(".artwork-card img").each(function () {
			let textHeight = $(this).closest("div").next().height();
			let contentHeight = $(this).closest(".artwork-card").height();
			$(this).height(contentHeight - textHeight);
		});
		ScrollTrigger.refresh();
	}

	let nn = gsap.matchMedia();
	nn.add("(min-width: 1024px)", () => {
		$(window).on("resize", imgHeight);

		let targets = gsap.utils.toArray($(".artist-section-main"));
		targets.forEach(function (target) {
			let artistSection = $(target);

			// this is to set the element to be really wide so the imgs have space to get as big as they can
			artistSection.width(50000);

			let introWidth = $(window).width() - 115;
			artistSection.find(".artist-section-intro").width(introWidth);

			// set height of posts so they can find thier width
			artistSection.find(".artwork-card img").each(function () {
				let textHeight = $(this).closest("div").next().height();
				let contentHeight = $(this).closest(".artwork-card").height();
				$(this).height(contentHeight - textHeight);
			});

			artistSection.find(".nft-card img").each(function () {
				let textHeight = $(this).parent().next().height();
				let contentHeight = $(this).closest(".nft-card").height();
				$(this).height(contentHeight - textHeight);
			});

			// videos need to wait until partially loaded to calc size
			if (artistSection.find("video").length) {
				let videoCount = artistSection.find("video").length;

				let i = 0;
				artistSection.find("video").each(function (index) {
					$(this).on("loadeddata", () => {
						i++;
						if (i == videoCount) {
							artistSection
								.find(".nft-card video")
								.each(function () {
									let textHeight = $(this)
										.parent()
										.next()
										.height();
									let contentHeight = $(this)
										.closest(".nft-card")
										.height();
									$(this).height(contentHeight - textHeight);
								});

							contentReady();
						}
					});
				});
			} else {
				contentReady();
			}

			function contentReady() {
				// get post quantity
				let postsNumber = artistSection.find(
					".artwork-card, .nft-card"
				).length;

				// get widths of all posts
				let posts = 0;
				artistSection
					.find(
						".artist-section-posts .artwork-card, .artist-section-posts .nft-card"
					)
					.each(function () {
						posts = posts + $(this).width();
					});

				// get quantity of gaps between posts
				let artworkGaps =
					artistSection.find(
						".artist-section-posts .artwork-card, .artist-section-posts .nft-card"
					).length - 1;

				// get widths of space between posts
				let gap =
					artworkGaps *
					parseInt(
						artistSection
							.find(".artist-section-posts")
							.css("row-gap")
					);

				// get padding of whole section

				// let secondaryImage = artistSection.find(
				// 	".primary-img .secondary-img"
				// );

				// if (secondaryImage.length) {
				// 	let artistSectionPosts = artistSection.find(
				// 		".artist-section-posts"
				// 	);

				// 	let artistSectionPostsPadding =
				// 		artistSectionPosts.css("padding-left");

				// 	setTimeout(function () {
				// 		let secondaryImageWidth =
				// 			secondaryImage.parent().width() / 2;

				// 		artistSectionPosts.css(
				// 			"padding-left",
				// 			parseInt(artistSectionPostsPadding) +
				// 				parseInt(secondaryImageWidth) +
				// 				"px"
				// 		);
				// 	}, 100);
				// }

				let padding = parseInt(
					artistSection
						.find(".artist-section-posts")
						.css("padding-left")
				);

				let newPadding =
					artistSection.find(".primary-img").width() -
					($(window).width() - 115) * 0.4;

				artistSection
					.find(".artist-section-posts")
					.css("padding-left", padding + newPadding);

				// console.log(padding);

				// if (artistSection.find(".artist-section-posts").length) {
				// 	artistSection
				// 		.find(".artist-section-posts")
				// 		.css(
				// 			"margin-left",
				// 			($(window).width() * 1.2) / 2 + 200
				// 		);
				// } else {
				// 	artistSection
				// 		.find(".artist-section-posts")
				// 		.css("margin-left", $(window).width() / 2);
				// }

				// console.log(padding);

				// artistSection
				// 	.find(".artist-section-posts")
				// 	.css("margin-left", $(window).width() / 2);

				let endSpace =
					introWidth / 2 -
					artistSection
						.find(".artist-section-posts")
						.find(".artwork-card:last-child, .nft-card:last-child")
						.width() /
						2;

				// if ($posts == 0) $posts = 0;
				if (isNaN(gap)) gap = 0;
				if (isNaN(padding)) padding = 0;
				if (isNaN(endSpace)) endSpace = 0;

				// console.log(artistSection.width());
				// console.log(introWidth);
				// console.log(posts);
				// console.log(gap);
				// console.log(padding);
				// console.log(endSpace);

				// set section width
				artistSection.width(
					parseInt(introWidth) +
						parseInt(posts) +
						parseInt(gap) +
						parseInt(padding) +
						parseInt(endSpace) +
						parseInt($(window).width() / 2)
				);

				// set vars for gsap
				let artistSectionWidth = artistSection.width();
				let scrollAmount = artistSectionWidth - introWidth * 0.8;
				let averagePostWidth = posts / postsNumber;
				let distance = postsNumber * averagePostWidth;

				if (isNaN(distance)) distance = $(".primary-img").width();

				let duration = 3;
				// if (posts == 0) {
				// 	scrollAmount =
				// 		$(window).width() * 1.2 - $(window).width() / 2;
				// } else {
				// 	duration = 3 + postsNumber * 100;
				// }

				// scroll whole section
				let horizontal = gsap
					.timeline({
						defaults: {
							ease: "linear",
						},
						scrollTrigger: {
							trigger: artistSection,
							start: "top top",
							end: "+=" + parseInt(distance),
							// markers: true,
							scrub: 0.5,
							pin: true,
							pinSpacing: true,
							anticipatePin: 1,
							ease: "none",
						},
					})
					.fromTo(
						artistSection,
						{
							x: "0%",
						},
						{
							x: scrollAmount * -1,
							duration: duration,
						},
						0
					);

				gsap.fromTo(
					artistSection.find(".artist-section-title"),
					{ x: 0 },
					{
						x: $(window).width(),
						ease: "none",
						scrollTrigger: {
							trigger: artistSection,
							ease: "none",
							start: "top top+=115",
							endTrigger: artistSection.find(
								".artist-section-intro .primary-img"
							),
							end: "bottom top-=50%",
							// markers: true,
							scrub: true,
							containerAnimation: horizontal,
						},
					}
				);

				// gsap.fromTo(
				// 	artistSection.find(".primary-img"),
				// 	{ left: "60%" },
				// 	{
				// 		left: "30%",
				// 		ease: "none",
				// 		scrollTrigger: {
				// 			trigger: artistSection,
				// 			ease: "none",
				// 			start: "top top+=115",
				// 			endTrigger: artistSection.find(
				// 				".artist-section-intro .primary-img"
				// 			),
				// 			end: "bottom top-=50%",
				// 			// markers: true,
				// 			scrub: true,
				// 			containerAnimation: horizontal,
				// 		},
				// 	}
				// );

				gsap.fromTo(
					artistSection.find(".primary-img img:not(.secondary-img)"),
					{ objectPosition: "0%" },
					{
						objectPosition: "120%",
						ease: "none",
						scrollTrigger: {
							trigger: artistSection,
							ease: "power1.in",
							start: "top top+=115",
							endTrigger: artistSection.find(
								".artist-section-intro .primary-img"
							),
							end: "bottom top",
							// markers: true,
							scrub: true,
							containerAnimation: horizontal,
						},
					}
				);
				gsap.fromTo(
					artistSection.find(".primary-img img.secondary-img"),
					{ objectPosition: "0%" },
					{
						objectPosition: "120%",
						ease: "none",
						scrollTrigger: {
							trigger: artistSection
								.find(".artist-section-intro .secondary-img")
								.parent(),
							ease: "power1.in",
							start: "top bottom",
							end: "bottom top+=115",
							// markers: true,
							scrub: true,
							containerAnimation: horizontal,
						},
					}
				);

				// remove loader once everything is calculated
				let loader = artistSection.find(".artist-loader");
				gsap.to(loader, {
					autoAlpha: 0,
					onComplete: () => loader.remove(),
				});
			}
		});

		return () => {
			$(".artwork-card img, .nft-card").height("");
			let bg = $(".artist-section").css("background-color");
			$(".artist-section").attr("style", "").css("background-color", bg);
		};
	});
}

function contentsSection() {
	// contents section

	$(".contents-section li button").on("mouseenter", function () {
		let id = $(this).attr("data-id");
		let imgs = $(this)
			.closest(".contents-section")
			.find(".contents-images img");
		let img = $(this)
			.closest(".contents-section")
			.find('.contents-images img[data-id="' + id + '"]');

		$(this).parent().siblings().children().removeClass("active");
		$(this).addClass("active");

		gsap.timeline()
			.to(imgs, { autoAlpha: 0 }, 0)
			.to(img, { autoAlpha: 1 }, 0);
	});

	// reveal links

	let mm = gsap.matchMedia();

	mm.add("(min-width: 800px)", () => {
		gsap.to(".contents-list li", {
			autoAlpha: 1,
			stagger: 0.1,
			scrollTrigger: {
				trigger: ".contents-section",
				start: "top 50%",
				// markers: true,
			},
		});
	});

	mm.add("(max-width: 799px)", () => {
		gsap.to(".contents-list li", {
			autoAlpha: 1,
			stagger: 0.1,
			scrollTrigger: {
				trigger: "body",
				start: "top top",
				// markers: true,
			},
		});
	});

	// reveal link after main contents section
	gsap.to(".open-contents", {
		autoAlpha: 1,
		scrollTrigger: {
			trigger: ".contents-section",
			start: "bottom top",
			endTrigger: ".next-section",
			end: "bottom bottom",
			// markers: true,
			toggleActions: "play reverse play reverse",
			refreshPriority: -1,
		},
	});

	// anchor links with swipe

	$(document).on("click", ".contents-button", function () {
		let anchor = $(this).attr("data-link");

		let page_color = $("#ve-sidebar").css("background-color") ?? "black";

		$("body").append(
			'<div style="background-color: ' +
				page_color +
				'" class="contents-wipe z-50 fixed inset-0 bg-black translate-x-full"></div>'
		);

		gsap.timeline({
			defaults: {
				duration: 0.5,
			},
		})
			.fromTo(
				".contents-wipe",
				{ xPercent: 0 },
				{
					xPercent: -100,
					ease: "power1.out",
					onComplete: () => {
						gsap.to(window, {
							duration: 0,
							scrollTo: "#" + anchor,
						});
					},
				}
			)
			.fromTo(
				".contents-wipe",
				{ xPercent: -100 },
				{
					xPercent: -200,
					ease: "power1.in",
					onComplete: () => {
						$(document).find(".contents-wipe").remove();
					},
				}
			);
	});

	// open content list in sidebar

	$(document).on("click", ".open-contents", function () {
		rightModal($(this).attr("data-content"), true);
	});
}

function sectionCountdown() {
	if ($("#countdown").length) {
		gsap.set("#countdown", { display: "block" });
		gsap.set(".hidden-content", { display: "none" });
		gsap.set($(".hidden-content").parent(".pin-spacer"), {
			display: "none",
		});

		ScrollTrigger.refresh();
		const targetDateAttr = $("#countdown").attr("data-datetime");
		const targetDate = new Date(targetDateAttr);
		const timezoneOffset = targetDate.getTimezoneOffset() * 60000; // Convert minutes to milliseconds
		const targetDateUK = new Date(targetDate.getTime() + timezoneOffset);
		const currentTime = new Date().getTime() + timezoneOffset;
		let difference = targetDateUK - currentTime;
		const interval = setInterval(() => {
			if (difference <= 0) {
				clearInterval(interval);
				gsap.set(".hidden-content", { display: "" });
				ScrollTrigger.refresh();
			} else {
				difference -= 1000;
			}
		}, 1000);

		function countdown(endtime) {
			var end = endtime.getTime();
			var timer = setInterval(function () {
				var now = new Date().getTime() + timezoneOffset;
				var remaining = end - now;
				var days = Math.floor(remaining / (1000 * 60 * 60 * 24));
				var hours = Math.floor(
					(remaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
				);
				var minutes = Math.floor(
					(remaining % (1000 * 60 * 60)) / (1000 * 60)
				);
				var seconds = Math.floor((remaining % (1000 * 60)) / 1000);
				$("#countdown").html(
					"OPENS IN " +
						days +
						"d " +
						hours +
						"h " +
						minutes +
						"m " +
						seconds +
						"s"
				);
				if (remaining <= 0) {
					clearInterval(timer);
					$("#countdown").remove();
				}
			}, 1000);
		}
		countdown(targetDateUK);
	}
}
function countdownBadges() {
	if ($(".countdown-badge").length) {
		$(".countdown-badge").each(function () {
			let thisBadge = $(this);

			gsap.set(thisBadge, { display: "block" });

			const targetDateAttr = thisBadge.attr("data-datetime");
			const targetDate = new Date(targetDateAttr);
			const timezoneOffset = targetDate.getTimezoneOffset() * 60000; // Convert minutes to milliseconds
			const targetDateUK = new Date(
				targetDate.getTime() + timezoneOffset
			);
			const currentTime = new Date().getTime() + timezoneOffset;

			function countdown(endtime) {
				var end = endtime.getTime();
				var timer = setInterval(function () {
					var now = new Date().getTime() + timezoneOffset;
					var remaining = end - now;
					var days = Math.floor(remaining / (1000 * 60 * 60 * 24));
					var hours = Math.floor(
						(remaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
					);
					var minutes = Math.floor(
						(remaining % (1000 * 60 * 60)) / (1000 * 60)
					);
					var seconds = Math.floor((remaining % (1000 * 60)) / 1000);
					thisBadge.html(
						"OPENS IN " +
							days +
							"d " +
							hours +
							"h " +
							minutes +
							"m " +
							seconds +
							"s"
					);
					if (remaining <= 0) {
						clearInterval(timer);
						thisBadge.remove();
					}
				}, 1000);
			}
			countdown(targetDateUK);
		});
	}
}

function rightModal(content, colour) {
	let contents = $(content).html();

	let page_color = colour
		? $("#ve-sidebar").css("background-color")
		: "black";

	$("body").append(
		'<div class="rev-content z-40 opacity-0 invisible fixed inset-0 bg-black text-white bg-opacity-50"><div style="background-color: ' +
			page_color +
			'" class="fixed [&>*]:text-base [&>*]:leading-6 translate-x-full w-[90%] lg:w-1/2 right-0 h-full overflow-y-auto ml-1/2 bg-black py-10 px-8 lg:py-20 lg:px-12 [&>.hide-revealable-content]:hidden"></div><button id="close-rev-content" class="font-heading absolute bottom-4 right-4 lg:bottom-8 lg:right-8 opacity-0 voices:mix-blend-difference">Close</button></div>'
	);

	$(document).find(".rev-content > div").append(contents);

	let openRevCont = gsap
		.timeline()
		.fromTo(
			$(document).find(".rev-content"),
			{
				autoAlpha: 0,
			},
			{
				autoAlpha: 1,
			}
		)
		.fromTo(
			$(document).find(".rev-content > div"),
			{
				x: "100%",
			},
			{
				x: "0%",
				ease: "power1.easeIn",
			}
		)
		.fromTo(
			$(document).find(".rev-content > div > *"),
			{
				y: 30,
				autoAlpha: 0,
			},
			{
				y: 0,
				autoAlpha: 1,
				ease: "power1.easeIn",
			}
		)
		.fromTo(
			$(document).find("#close-rev-content"),
			{
				autoAlpha: 0,
			},
			{
				autoAlpha: 1,
				ease: "power1.easeIn",
			},
			"-=.25"
		);

	$(document).on("click", "#close-rev-content, .rev-content", function () {
		openRevCont
			.reverse()
			.eventCallback("onReverseComplete", () =>
				$(document).find(".rev-content").remove()
			);
	});
}

function artworkVideos() {
	// if ($(document).find(".artworkCard video")) {
	// 	$(document)
	// 		.find(".artworkCard")
	// 		.on("mouseenter", function () {
	// 			$(this).find("video").get(0).play();
	// 		});
	// 	$(document)
	// 		.find(".artworkCard")
	// 		.on("mouseleave", function () {
	// 			$(this).find("video").get(0).pause();
	// 		});
	// }
}

// Direct link to enquire form of an artwork

// Get the URL parameter
var parameter = window.location.search.substring(1).split("&")[0];

// Check if the parameter exists
if (parameter !== undefined) {
	// Get the value of the parameter
	var parameter = parameter.split("=")[0];

	// Check if the value is equal to enquire
	if (parameter == "enquire") {
		$(function () {
			// click enquire button to open overlay
			$("#artworkButtons .artworkEnquire").trigger("click");
		});
	}
}

// Deliver clip of nft video on nft card

function getFilenameWithoutExtension(url) {
	const lastIndexOfSlash = url.lastIndexOf("/");

	// If the "/" character is not found, return the entire URL as the filename.
	if (lastIndexOfSlash === -1) {
		return url;
	}

	const filename = url.substring(lastIndexOfSlash + 1);

	// Get the index of the last "." character in the filename.
	const lastIndexOfDot = filename.lastIndexOf(".");

	// If the "." character is not found, return the entire filename.
	if (lastIndexOfDot === -1) {
		return filename;
	}

	// Get the substring from the beginning of the filename to the index of the last "." character.
	const filenameWithoutExtension = filename.substring(0, lastIndexOfDot);

	// Return the filename without the extension.
	return filenameWithoutExtension;
}

// if ($(".nft-card").length) {
function getFiveSecondClipsFromCloudinary() {
	$(".nft-card").each(function () {
		let video = $(this).find("video");
		if (video.length) {
			let videoURL = video.attr("data-video");
			let filenameWithoutExtension =
				getFilenameWithoutExtension(videoURL);
			let myVideo = cld
				.video(filenameWithoutExtension)
				.videoEdit(trim().startOffset("0.0").endOffset("5.0"))
				.delivery(quality(autoQuality()))
				.delivery(format(autoFormat()))
				.resize(fit().width(550));
			let cloudinaryURL = myVideo.toURL();
			video.attr("src", cloudinaryURL);
		}
	});
}
// }

setTimeout(function () {
	$("body").find("#wpadminbar").attr("data-barba-prevent", "all");
}, 5000);
