import jQuery from "jquery";
window.$ = window.jQuery = jQuery;

import { gsap } from "gsap";

export default function openEnquire() {
	let openEnquire = gsap
		.timeline({
			paused: true,
		})
		.fromTo("#artworkEnquireOverlay", { autoAlpha: 0 }, { autoAlpha: 1 })
		.addLabel("faded")
		.fromTo(
			"#artworkEnquireContent",
			{ x: "-100%" },
			{
				x: 0,
				onComplete: () => {
					$("#logo").addClass("text-white");
				},
				onReverseComplete: () => {
					$("#logo").removeClass("text-white");
				},
			},
			"faded"
		)
		.fromTo(
			"#artworkEnquireContent > div",
			{ y: 100, autoAlpha: 0 },
			{ y: 0, autoAlpha: 1, duration: 1, ease: "power1.out" },
			"faded+=.25"
		);

	$(document).on("click", ".artworkEnquire", function (e) {
		e.stopImmediatePropagation();

		let form_title = $(this).attr("data-form-title") ?? "How can we help?";
		$("#artworkEnquireForm > h3").html(form_title);

		let type = $(this).closest(".artwork-card").attr("data-type");
		if ($(this).parents(".artworkOverlayItem[data-type='print']").length) {
			type = "print";
		}

		if (type == "print") {
			gsap.set(".hbspt-form", { display: "none" });
			gsap.set(".hbspt-form2", { display: "block" });
		} else {
			gsap.set(".hbspt-form", { display: "block" });
			gsap.set(".hbspt-form2", { display: "none" });
		}

		if ($(this).hasClass("requestCatalogButton")) {
			let catalog = true;
		}

		// clear appended artwork area if 2nd enquiry
		$(document).find("#formArtwork").remove();

		// add artwork area to hubspot form
		$(document)
			.find("#artworkEnquireContent .hs_last_sales_enquiry_message")
			.append(
				'<div id="formArtwork" class="flex items-center space-x-4 mt-4 pb-4 border-b border-white border-opacity-[.15]"><div id="formArtworkImage"><img /></div><div id="formArtworkContent"></div></div>'
			);

		// open enquire overlay
		openEnquire.timeScale(1).play();

		// get data from html
		let artist, title, image, year, info, catalog, catalogSub, imageEmail;
		if ($(this).parents(".artworkOverlayItem").length) {
			// If single artwork page/overlay
			artist = $(document)
				.find(".artworkOverlayItem.swiper-slide-active .artworkArtist")
				.text();
			title = $(document)
				.find(".artworkOverlayItem.swiper-slide-active .artworkTitle")
				.text();
			image = $(document)
				.find(".artworkOverlayItem.swiper-slide-active .zoomImg")
				.attr("data-thumb");
			imageEmail = $(document)
				.find(".artworkOverlayItem.swiper-slide-active .zoomImg")
				.attr("data-thumbemail");
			year = $(document)
				.find(".artworkOverlayItem.swiper-slide-active .artworkYear")
				.text();
			info = $(document)
				.find(".artworkOverlayItem.swiper-slide-active .artworkContent")
				.html();
		} else {
			// if artwork card
			artist = $(this).closest(".artworkCard").attr("data-artist");
			title = $(this)
				.closest(".artworkCard")
				.find(".artworkCardTitle")
				.text();
			image = $(this).closest(".artworkCard").attr("data-image");
			imageEmail = $(this)
				.closest(".artworkCard")
				.attr("data-imageemail");
			year = $(this)
				.closest(".artworkCard")
				.find(".artworkCardYear")
				.text();
			info = $(this)
				.closest(".artworkCard")
				.find(".artworkCardContent")
				.html();

			catalog = $(this).attr("data-catalog");
			catalogSub = $(this).attr("data-catalog-subtitle");
		}

		if (info == undefined) {
			info = "";
		}

		if (catalog) {
			artist = "Request Catalogue";
			title = catalog + " - " + catalogSub;
			// $("#artworkEnquireForm #formArtworkContent").hide();
		}

		// add data to hubspot form
		$('#artworkEnquireForm input[name="subject"]').val(
			artist + " - " + title
		);

		console.log(artist);
		$(document)
			.find("#artworkEnquireForm")
			.attr("data-artist", artist.trim());

		$(document)
			.find(
				"#artworkEnquireForm .hs_artist_tags_2022__cloned_ input[type='checkbox']"
			)
			.prop("checked", false);

		setTimeout(function () {
			// setInterval(function () {

			artist = artist.trim();

			$(document)
				.find(
					"#artworkEnquireForm .hs_artist_tags_2022__cloned_ input[type='checkbox'][value='" +
						artist +
						"']"
				)
				.prop("checked", true);

			info = info.trim().replace(/(<([^>]+)>)/gi, "");

			const [medium, dimensions] = info.split(/<br>|\n/);

			$(
				"#artworkEnquireForm .hs-last_gallery_enquiry_artwork_title input[type='hidden']"
			).val(artist + ", " + title + ", " + year + ", " + info);

			// [CURRENT] Sales Enquiry Form

			$(
				"#artworkEnquireForm .hs-last_gallery_enquiry_artist_name input[type='hidden']"
			).val(artist);

			$(
				"#artworkEnquireForm .hs-last_gallery_enquiry_artwork_name input[type='hidden']"
			).val(title);

			$(
				"#artworkEnquireForm .hs-last_gallery_enquiry_artwork_size input[type='hidden']"
			).val(dimensions);

			$(
				"#artworkEnquireForm .hs-last_gallery_enquiry_artwork_medium input[type='hidden']"
			).val(medium);

			$(
				"#artworkEnquireForm .hs_last_gallery_enquiry_artwork_image input[type='hidden']"
			).val(imageEmail);

			// [CURRENT] Editions Make Offer Form

			$(
				"#artworkEnquireForm .hs-last_editions_enquiry_artist_name input[type='hidden']"
			).val(artist);

			$(
				"#artworkEnquireForm .hs-last_editions_enquiry_artwork_name input[type='hidden']"
			).val(title);

			$(
				"#artworkEnquireForm .hs-last_editions_enquiry_artwork_size input[type='hidden']"
			).val(dimensions);

			$(
				"#artworkEnquireForm .hs-last_editions_enquiry_artwork_medium input[type='hidden']"
			).val(medium);

			$(
				"#artworkEnquireForm .hs_last_editions_enquiry_artwork_image input[type='hidden']"
			).val(imageEmail);

			// }, 3000);
		}, 5000);

		$('#artworkEnquireForm input[name="artist"]').val(artist);
		$('#artworkEnquireForm input[name="catalog"]').val(catalog);

		$("#artworkEnquireForm #formArtworkImage img").attr("src", image);
		$("#artworkEnquireForm #formArtworkContent").append(
			"<div>" +
				artist +
				"</div><div>" +
				title +
				"</div><div>" +
				year +
				"</div><div>" +
				info +
				"</div>"
		);
	});

	$(document).on(
		"click",
		"#closeArtworkEnquireBG, #closeArtworkEnquire",
		function () {
			$(document)
				.find(
					"#artworkEnquireForm .hs_artist_tags_2022__cloned_ input[type='checkbox']"
				)
				.prop("checked", false);

			// close enquiry overlay
			openEnquire.timeScale(1.5).reverse();

			// reset form
			setTimeout(function () {
				$("#artworkEnquireForm #formArtworkContent").empty().show();
				$("#artworkEnquireForm #formArtworkImage img").attr("src", "");

				$(document)
					.find("#artworkEnquireForm .hbspt-form form")
					.remove();

				$(document)
					.find("#artworkEnquireForm .hbspt-form2 form")
					.remove();

				hbspt.forms.create({
					region: "na1",
					portalId: "5818574",
					formId: "2a8f7da3-968d-4f3c-b1d4-de0623f680d7",
					target: "#artworkEnquireForm .hbspt-form", // Add the element class where you need to create the form again
					onFormReady: () => {
						fetch("https://reallyfreegeoip.org/json/")
							.then((response) => response.json())
							.then((json) => {
								$("#artworkEnquireForm .hbspt-form")
									.find('.hs_zip input[name="zip"]')
									.val(json.zip_code);
								$("#artworkEnquireForm .hbspt-form")
									.find(
										'.hs_ip_address input[name="ip_address"]'
									)
									.val(json.ip);
							});
					},
					onFormSubmit: function ($form) {
						console.log("submitting");
						jQuery(document)
							.find("#artworkEnquireForm")
							.find(
								"input[type='checkbox'][value='" +
									jQuery("#artworkEnquireForm").attr(
										"data-artist"
									) +
									"']"
							)
							.prop("checked", true)
							.change();

						setTimeout(() => {
							$(document)
								.find("#closeArtworkEnquire")
								.trigger("click");
						}, 3000);
					},
				});

				hbspt.forms.create({
					region: "na1",
					portalId: "5818574",
					formId: "5fb24565-14b9-4fdc-b7c1-9cf253ade13d",
					target: "#artworkEnquireForm .hbspt-form2", // Add the element class where you need to create the form again
					onFormReady: () => {
						fetch("https://reallyfreegeoip.org/json/")
							.then((response) => response.json())
							.then((json) => {
								$("#artworkEnquireForm .hbspt-form2")
									.find('.hs_zip input[name="zip"]')
									.val(json.zip_code);
								$("#artworkEnquireForm .hbspt-form2")
									.find(
										'.hs_ip_address input[name="ip_address"]'
									)
									.val(json.ip);
							});
					},
					onFormSubmit: function ($form) {
						console.log("submitting");
						jQuery(document)
							.find("#artworkEnquireForm")
							.find(
								"input[type='checkbox'][value='" +
									jQuery("#artworkEnquireForm").attr(
										"data-artist"
									) +
									"']"
							)
							.prop("checked", true)
							.change();

						setTimeout(() => {
							$(document)
								.find("#closeArtworkEnquire")
								.trigger("click");
						}, 3000);
					},
				});
			}, 1000);
		}
	);
}
