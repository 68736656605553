import jQuery, { css } from "jquery";
window.$ = window.jQuery = jQuery;

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { SplitText } from "gsap/SplitText";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

gsap.registerPlugin(ScrollTrigger, SplitText, ScrollToPlugin);
gsap.config({ nullTargetWarn: false });
import Glide from "@glidejs/glide";

export default function home() {
	// $("body, html").css("touch-action", "none");

	new SplitText(".homeHeroTitle", {
		type: "words",
	});
	new SplitText(".homeHeroTitle div", {
		type: "chars",
	});

	let homeSlider = new Glide("#home-hero", {
		animationDuration: 500,
		autoplay: 8000,
		gap: 0,
	});

	$("#home-hero").css("height", $(window).height());

	homeSlider.on("run.before", () => {
		gsap.timeline({
			defaults: {
				overwrite: "auto",
				duration: 0.4,
				// ease: "linear",
			},
		})
			.fromTo(
				".glide__slide--active .homeHeroImage",
				{
					scale: 1,
					autoAlpha: 1,
				},
				{
					scale: 1.3,
					autoAlpha: 0,
					ease: "power1.in",
					// duration: 0.6,
				},
				0
			)
			.fromTo(
				".glide__slide--active .homeHeroContent .homeHeroIntro",
				{ autoAlpha: 1 },
				{ autoAlpha: 0 },
				// 0.2
				0
			)
			.fromTo(
				".glide__slide--active .homeHeroContent .move-down",
				{ y: 0, autoAlpha: 1 },
				{ y: 50, autoAlpha: 0 },
				// 0.2
				0
			);
		//
	});
	homeSlider.on(["run.after", "build.after"], () => {
		gsap.timeline({
			defaults: {
				overwrite: "auto",
				// ease: "linear",
			},
		})
			.set(".glide__slide--active .homeHeroImage", { scale: 1 })
			.set(".glide__slide--active .homeHeroContent .homeHeroTitle", {
				autoAlpha: 1,
				y: 0,
			})
			.set(".glide__slide--active .homeHeroContent a", {
				y: -50,
				autoAlpha: 0,
			})

			.addLabel("start")

			.fromTo(
				".glide__slide--active .homeHeroTitle div div",
				{ opacity: 0, y: "-100%" },
				{ opacity: 1, y: "0%", stagger: 0.02, duration: 1 },
				"start"
			)

			.fromTo(
				".glide__slide--active .homeHeroImage",
				{
					autoAlpha: 0,
				},
				{
					autoAlpha: 1,
					// ease: "power1.in",
				},
				"start+=.25"
			)

			.fromTo(
				".glide__slide--active .homeHeroIntro",
				{
					autoAlpha: 0,
				},
				{
					autoAlpha: 1,
				},
				"start+=.5"
			)
			.fromTo(
				".glide__slide--active .homeHeroIntro h2 div div",
				{
					width: 0,
				},
				{
					width: "100%",
					duration: 1,
					// ease: "power4.out",
				},
				"start+=.5"
			)
			.to(
				".glide__slide--active .button",
				{
					y: 0,
					autoAlpha: 1,
					duration: 1,
					ease: "power4.out",
				},
				"start"
			);
	});
	homeSlider.mount();

	// $("body").on(
	// 	"wheel touchmove",
	// 	_.debounce(
	// 		function (e) {
	// 			if (e.originalEvent.wheelDelta / 120 > 0) {
	// 				homeSlider.go("<");
	// 			} else {
	// 				homeSlider.go(">");
	// 			}
	// 		},
	// 		1000,
	// 		{
	// 			leading: true,
	// 			trailing: false,
	// 		}
	// 	)
	// );

	$(document).on("resize", function (e) {
		$("#home-hero").css("height", $(window).innerHeight());
	});
}
