import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export function acfmap() {
	/*
	 *  new_map
	 *
	 *  This function will render a Google Map onto the selected jQuery element
	 *
	 *  @type	function
	 *  @date	8/11/2013
	 *  @since	4.3.0
	 *
	 *  @param	$el (jQuery element)
	 *  @return	n/a
	 */

	function new_map($el) {
		// var
		var $markers = $el.find(".marker");

		// vars
		var args = {
			zoom: 17,
			center: new google.maps.LatLng(0, 0),
			disableDefaultUI: true,
			mapTypeId: google.maps.MapTypeId.ROADMAP,
			styles: [
				{ elementType: "geometry", stylers: [{ color: "#f5f5f5" }] },
				{
					elementType: "labels.icon",
					stylers: [{ visibility: "off" }],
				},
				{
					elementType: "labels.text.fill",
					stylers: [{ color: "#616161" }],
				},
				{
					elementType: "labels.text.stroke",
					stylers: [{ color: "#f5f5f5" }],
				},
				{
					featureType: "administrative.land_parcel",
					elementType: "labels.text.fill",
					stylers: [{ color: "#bdbdbd" }],
				},
				{
					featureType: "poi",
					elementType: "geometry",
					stylers: [{ color: "#eeeeee" }],
				},
				{
					featureType: "poi",
					elementType: "labels.text.fill",
					stylers: [{ color: "#757575" }],
				},
				{
					featureType: "poi.park",
					elementType: "geometry",
					stylers: [{ color: "#e5e5e5" }],
				},
				{
					featureType: "poi.park",
					elementType: "labels.text.fill",
					stylers: [{ color: "#9e9e9e" }],
				},
				{
					featureType: "road",
					elementType: "geometry",
					stylers: [{ color: "#ffffff" }],
				},
				{
					featureType: "road.arterial",
					elementType: "labels.text.fill",
					stylers: [{ color: "#757575" }],
				},
				{
					featureType: "road.highway",
					elementType: "geometry",
					stylers: [{ color: "#dadada" }],
				},
				{
					featureType: "road.highway",
					elementType: "labels.text.fill",
					stylers: [{ color: "#616161" }],
				},
				{
					featureType: "road.local",
					elementType: "labels.text.fill",
					stylers: [{ color: "#9e9e9e" }],
				},
				{
					featureType: "transit.line",
					elementType: "geometry",
					stylers: [{ color: "#e5e5e5" }],
				},
				{
					featureType: "transit.station",
					elementType: "geometry",
					stylers: [{ color: "#eeeeee" }],
				},
				{
					featureType: "water",
					elementType: "geometry",
					stylers: [{ color: "#c9c9c9" }],
				},
				{
					featureType: "water",
					elementType: "labels.text.fill",
					stylers: [{ color: "#9e9e9e" }],
				},
			],
		};

		// create map
		var map = new google.maps.Map($el[0], args);

		// add a markers reference
		map.markers = [];

		// add markers
		$markers.each(function () {
			add_marker($(this), map);
		});

		// center map
		center_map(map);

		// return
		return map;
	}

	/*
	 *  add_marker
	 *
	 *  This function will add a marker to the selected Google Map
	 *
	 *  @type	function
	 *  @date	8/11/2013
	 *  @since	4.3.0
	 *
	 *  @param	$marker (jQuery element)
	 *  @param	map (Google Map object)
	 *  @return	n/a
	 */

	function add_marker($marker, map) {
		// var
		var latlng = new google.maps.LatLng(
			$marker.attr("data-lat"),
			$marker.attr("data-lng")
		);

		// create marker
		var marker = new google.maps.Marker({
			position: latlng,
			map: map,
			// icon: "https://maps.gstatic.com/mapfiles/transparent.png",
		});

		// add to array
		map.markers.push(marker);

		// if marker contains HTML, add it to an infoWindow
		if ($marker.html()) {
			// create info window
			var infowindow = new google.maps.InfoWindow({
				content: $marker.html(),
			});

			// show info window when marker is clicked
			google.maps.event.addListener(marker, "click", function () {
				infowindow.open(map, marker);
			});
		}
	}

	/*
	 *  center_map
	 *
	 *  This function will center the map, showing all markers attached to this map
	 *
	 *  @type	function
	 *  @date	8/11/2013
	 *  @since	4.3.0
	 *
	 *  @param	map (Google Map object)
	 *  @return	n/a
	 */

	function center_map(map) {
		// vars
		var bounds = new google.maps.LatLngBounds();

		// loop through all markers and create bounds
		$.each(map.markers, function (i, marker) {
			var latlng = new google.maps.LatLng(
				marker.position.lat(),
				marker.position.lng()
			);

			bounds.extend(latlng);
		});

		// only 1 marker?
		if (map.markers.length == 1) {
			// set center of map
			map.setCenter(bounds.getCenter());
			map.setZoom(17);
		} else {
			// fit to bounds
			map.fitBounds(bounds);
		}
	}

	/*
	 *  document ready
	 *
	 *  This function will render each map when the document is ready (page has loaded)
	 *
	 *  @type	function
	 *  @date	8/11/2013
	 *  @since	5.0.0
	 *
	 *  @param	n/a
	 *  @return	n/a
	 */
	// global var
	var map = null;

	$(document).ready(function () {
		$(".acf-map").each(function () {
			// create map
			map = new_map($(this));
		});

		setTimeout(function () {
			ScrollTrigger.create({
				trigger: $(".acf-map"),
				start: "top top",
				endTrigger: "#contactMain",
				end: "bottom bottom",
				// markers: true,
				pin: true,
			});
		}, 1000);
	});
}
