import jQuery from "jquery";
window.$ = window.jQuery = jQuery;

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export default function splashHeading() {
	$(document)
		.find(".panel")
		.each(function () {
			if ($(this).find(".splash-heading").length) {
				let thisPanel = $(this);

				ScrollTrigger.create({
					trigger: thisPanel,
					start: "top center",
					end: "bottom center",
					// markers: true,
					onEnter: function () {
						gsap.timeline()
							.to(thisPanel.find(".splash-heading"), {
								autoAlpha: 1,
							})
							.to(
								thisPanel.find(".splash-heading"),
								{ autoAlpha: 0, duration: 0.25 },
								1.5
							);
					},
					onEnterBack: function () {
						gsap.timeline()
							.to(thisPanel.find(".splash-heading"), {
								autoAlpha: 1,
							})
							.to(
								thisPanel.find(".splash-heading"),
								{ autoAlpha: 0, duration: 0.25 },
								1.5
							);
					},
					onLeave: function () {
						gsap.timeline().to(thisPanel.find(".splash-heading"), {
							autoAlpha: 0,
							duration: 0.25,
							overwrite: true,
						});
					},
					onLeaveBack: function () {
						gsap.timeline().to(thisPanel.find(".splash-heading"), {
							autoAlpha: 0,
							duration: 0.25,
							overwrite: true,
						});
					},
				});
			}
		});
}
