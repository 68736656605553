import jQuery from "jquery";
window.$ = window.jQuery = jQuery;

import { gsap } from "gsap";

export default function openEnquireCatalog() {
	let openEnquireCatalog = gsap
		.timeline({
			paused: true,
		})
		.fromTo(
			"#artworkEnquireCatalogOverlay",
			{ autoAlpha: 0 },
			{ autoAlpha: 1 }
		)
		.addLabel("faded")
		.fromTo(
			"#artworkEnquireCatalogContent",
			{ x: "-100%" },
			{
				x: 0,
				onComplete: () => {
					$("#logo").addClass("text-white");
				},
				onReverseComplete: () => {
					$("#logo").removeClass("text-white");
				},
			},
			"faded"
		)
		.fromTo(
			"#artworkEnquireCatalogContent > div",
			{ y: 100, autoAlpha: 0 },
			{ y: 0, autoAlpha: 1, duration: 1, ease: "power1.out" },
			"faded+=.25"
		);

	$(document).on("click", ".requestCatalogButton", function (e) {
		e.stopPropagation();

		let form_title = $(this).attr("data-form-title")
			? $(this).attr("data-form-title")
			: "Request Catalogue";
		$("#artworkEnquireCatalogForm > h3").html(form_title);

		if ($(this).hasClass("requestCatalogButton")) {
			let catalog = true;
		}

		// clear appended artwork area if 2nd enquiry
		$(document).find("#formArtwork").remove();

		// add artwork area to hubspot form
		$(document)
			.find(
				"#artworkEnquireCatalogContent .hs_last_sales_enquiry_message"
			)
			.append(
				'<div id="formArtwork" class="flex items-center space-x-4 mt-4 pb-4 border-b border-white border-opacity-[.15]"><div id="formArtworkImage"><img /></div><div id="formArtworkContent"></div></div>'
			);

		// open enquireCatalog overlay
		openEnquireCatalog.timeScale(1).play();

		// get data from html
		let artist, title, image, year, info, catalog, catalogSub;
		if ($(this).parents("#artworkOverlay").length) {
			// If single artwork page/overlay
			artist = $(document)
				.find("#artworkOverlay .swiper-slide-active .artworkArtist")
				.text();
			title = $(document)
				.find("#artworkOverlay .swiper-slide-active .artworkTitle")
				.text();
			image = $(document)
				.find("#artworkOverlay .swiper-slide-active .zoomImg")
				.attr("data-thumb");
			year = $(document)
				.find("#artworkOverlay .swiper-slide-active .artworkYear")
				.text();
			info = $(document)
				.find("#artworkOverlay .swiper-slide-active .artworkContent")
				.html();
		} else {
			// if artwork card
			artist = $(this).closest(".artworkCard").attr("data-artist");
			title = $(this)
				.closest(".artworkCard")
				.find(".artworkCardTitle")
				.text();
			image = $(this).closest(".artworkCard").attr("data-image");
			year = $(this)
				.closest(".artworkCard")
				.find(".artworkCardYear")
				.text();
			info = $(this)
				.closest(".artworkCard")
				.find(".artworkCardContent")
				.html();

			catalog = $(this).attr("data-catalog");
			catalogSub = $(this).attr("data-catalog-subtitle");
		}

		if (info == undefined) {
			info = "";
		}

		let artistNames = [];
		let artistName = catalog;
		if (catalog) {
			artist = "Request Catalogue";
			title = catalog;

			if ($(this).find("div").length) {
				$(this)
					.find("div")
					.each(function () {
						artistNames.push($(this).html());
					});

				console.log(artistNames);
			}

			if (catalogSub) {
				title = title.concat(" - ", catalogSub);
			}
			// $("#artworkEnquireCatalogForm #formArtworkContent").hide();
		}

		// add data to hubspot form
		$('#artworkEnquireCatalogForm input[name="subject"]').val(
			artist + " - " + title
		);

		$("#artworkEnquireCatalogForm").attr("data-artist", artist.trim());

		$(document)
			.find(
				"#artworkEnquireCatalogForm .hs_artist_tags_2022__cloned_ input[type='checkbox']"
			)
			.prop("checked", false);

		setTimeout(function () {
			// setInterval(function () {

			if (artistNames.length > 0) {
				artistNames.map(function (name) {
					console.log(name);
					$(document)
						.find(
							"#artworkEnquireCatalogForm .hs_artist_tags_2022__cloned_ input[type='checkbox'][value='" +
								name +
								"']"
						)
						.prop("checked", true);
				});
			} else {
				artist = artistName.trim();
				$(document)
					.find(
						"#artworkEnquireCatalogForm .hs_artist_tags_2022__cloned_ input[type='checkbox'][value='" +
							artist +
							"']"
					)
					.prop("checked", true);
			}

			// $(document)
			// 	.find(
			// 		"#artworkEnquireCatalogForm .hs_artist_tags_2022__cloned_ input[type='checkbox'][value='" +
			// 			artist +
			// 			"']"
			// 	)
			// 	.prop("checked", true);

			info = info.trim().replace(/(<([^>]+)>)/gi, "");

			$(
				"#artworkEnquireCatalogForm .hs-last_gallery_enquiry_artwork_title input[type='hidden']"
			).val(artist + ", " + title + ", " + year + ", " + info);
			// }, 3000);
		}, 5000);

		$('#artworkEnquireCatalogForm input[name="artist"]').val(artist);
		$('#artworkEnquireCatalogForm input[name="catalog"]').val(catalog);

		$("#artworkEnquireCatalogForm #formArtworkImage img").attr(
			"src",
			image
		);
		$("#artworkEnquireCatalogForm #formArtworkContent").append(
			"<div>" +
				artist +
				"</div><div>" +
				title +
				"</div><div>" +
				year +
				"</div><div>" +
				info +
				"</div>"
		);
	});

	$(document).on(
		"click",
		"#closeArtworkEnquireCatalogBG, #closeArtworkEnquireCatalog",
		function () {
			$(document)
				.find(
					"#artworkEnquireCatalogForm .hs_artist_tags_2022__cloned_ input[type='checkbox']"
				)
				.prop("checked", false);

			// close enquiry overlay
			openEnquireCatalog.timeScale(1.5).reverse();

			// reset form
			setTimeout(function () {
				$("#artworkEnquireCatalogForm #formArtworkContent")
					.empty()
					.show();
				$("#artworkEnquireCatalogForm #formArtworkImage img").attr(
					"src",
					""
				);

				$(document)
					.find("#artworkEnquireCatalogForm .hbspt-form form")
					.remove();

				hbspt.forms.create({
					region: "na1",
					portalId: "5818574",
					formId: "dc0221cb-0281-4b17-a3ae-df929bb7bda7",
					target: "#artworkEnquireCatalogForm .hbspt-form", // Add the element class where you need to create the form again
					onFormReady: () => {
						fetch("https://reallyfreegeoip.org/json/")
							.then((response) => response.json())
							.then((json) => {
								$("#artworkEnquireCatalogForm .hbspt-form")
									.find('.hs_zip input[name="zip"]')
									.val(json.zip_code);
								$("#artworkEnquireCatalogForm .hbspt-form")
									.find(
										'.hs_ip_address input[name="ip_address"]'
									)
									.val(json.ip);
							});
					},
					onFormSubmit: function ($form) {
						console.log("submitting");
						jQuery(document)
							.find("#artworkEnquireCatalogForm")
							.find(
								"input[type='checkbox'][value='" +
									jQuery("#artworkEnquireCatalogForm").attr(
										"data-artist"
									) +
									"']"
							)
							.prop("checked", true)
							.change();

						setTimeout(() => {
							$(document)
								.find("#closeArtworkEnquireCatalog")
								.trigger("click");
						}, 3000);
					},
				});
			}, 1000);
		}
	);
}
