import jQuery, { css } from "jquery";
window.$ = window.jQuery = jQuery;

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { SplitText } from "gsap/SplitText";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

gsap.registerPlugin(ScrollTrigger, SplitText, ScrollToPlugin);
gsap.config({ nullTargetWarn: false });

import artworkOverlay from "./artwork-overlay";

export default function search() {
	// Search overlay

	let openSearch = gsap
		.timeline({
			paused: true,
		})
		.fromTo(".header-right, .header-menu", { y: 0 }, { y: -100 })
		.fromTo("#searchOverlay", { y: "-100%" }, { y: "0%" }, 0)
		.fromTo(
			"#searchOverlay form",
			{
				autoAlpha: 0,
			},
			{
				autoAlpha: 1,
				onComplete: () => {
					$("#searchOverlay #search").focus();
					$("header #logo").addClass("text-white");
				},
				onReverseComplete: () => {
					$("header #logo").removeClass("text-white");
				},
			}
		)
		.fromTo(
			"#closeSearchOverlay",
			{ opacity: 0, x: "100%" },
			{ opacity: 1, x: "0%" }
		);
	// .fromTo("header #logo", { color: "", }, { color: "white" }, 1.5);

	$(document).on("click", "#openSearchOverlay", function () {
		openSearch.timeScale(1).play();
		$("#searchOverlay").addClass("fixed");
		$("header").addClass("pointer-events-none");
		$("body, html").addClass("overflow-hidden h-screen");
	});
	$(document).on("click", "#closeSearchOverlay", function () {
		openSearch.timeScale(1).reverse();
		$("#searchOverlay").removeClass("fixed");
		$("header").removeClass("pointer-events-none");
		$("body, html").removeClass("overflow-hidden h-screen");
	});

	function do_search(search) {
		let value = search;

		if (value.length >= 3) {
			// get JSON url
			var WpJsonUrl = document.querySelector(
				'link[rel="https://api.w.org/"]'
			).href;
			// then take out the '/wp-json/' part
			var homeurl = WpJsonUrl.replace("/wp-json/", "");

			// remove spaces and replace with + to create usualble url
			value = value.replace(" ", "+");

			$("#searchLoadArea").load(
				homeurl + "/?s=" + value + " #searchLoad",
				function (response, status, xhr) {
					$("#searchLoad").imagesLoaded(function () {
						$(document)
							.find("#searchLoad .col-span-1")
							.each(function () {
								// setTimeout(() => {
								gsap.to($(this).find(".intro-heading"), {
									opacity: 1,
								});
								// }, 1000);

								gsap.timeline().fromTo(
									$(this),
									{
										opacity: 0,
										y: "100px",
									},
									{
										// scrollTrigger: {
										// 	trigger: $(this),
										// 	start: "top 85%",
										// 	markers: true,
										// },
										y: "0px",
										opacity: 1,
										duration: 2,
									}
								);
							});
					});

					artworkOverlay();

					$(document).on("click", "#searchLoad a", function () {
						openSearch.timeScale(1.5).reverse();
						$("#searchOverlay").removeClass("fixed");
						$("header").removeClass("pointer-events-none");
						$("body, html").removeClass("overflow-hidden h-screen");
						gsap.set("body, html", { overflow: "" });
					});
				}
			);
		}
	}

	function debounce(func, wait, immediate) {
		var timeout;
		return function () {
			var context = this,
				args = arguments;
			var later = function () {
				timeout = null;
				if (!immediate) func.apply(context, args);
			};
			var callNow = immediate && !timeout;
			clearTimeout(timeout);
			timeout = setTimeout(later, wait);
			if (callNow) func.apply(context, args);
		};
	}

	$(document).on(
		"keyup",
		"#mainsearch #search",
		debounce(function (e) {
			e.preventDefault();
			let value = $(this).val();
			console.log(value);
			do_search(value);
		}, 700)
	);
}
