import jQuery from "jquery";
window.$ = window.jQuery = jQuery;

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export default function exhibitionCard() {
	$(document)
		.find(".exhibitionCard")
		.each(function () {
			ScrollTrigger.create({
				trigger: $(this),
				start: "top bottom",
				onEnter: () => {
					gsap.fromTo(
						$(this),
						{
							y: 50,
							opacity: 0,
						},
						{
							y: 0,
							opacity: 1,
							duration: 2,
							ease: "power1.out",
						}
					);
				},
				onLeave: () => {
					gsap.to($(this), {
						opacity: 0,
					});
				},
				onLeaveBack: () => {
					gsap.to($(this), {
						opacity: 0,
					});
				},
				onEnterBack: () => {
					gsap.fromTo(
						$(this),
						{
							y: -50,
							opacity: 0,
						},
						{
							y: 0,
							opacity: 1,
							duration: 2,
							ease: "power1.out",
						}
					);
				},
			});
		});
}
