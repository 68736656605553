import jQuery, { css } from "jquery";
window.$ = window.jQuery = jQuery;
import Cookies from "js-cookie";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Flip } from "gsap/Flip";
import { init } from "../index";
import Swiper, { EffectFade, Navigation, Scrollbar, Parallax } from "swiper";

gsap.registerPlugin(ScrollTrigger);
gsap.config({ nullTargetWarn: false });

export default function voicesIntro() {
	let mm = gsap.matchMedia();

	let intro;

	const swiper = new Swiper(".featured-exhibitions", {
		modules: [Navigation, EffectFade],
		spaceBetween: 0,
		loop: true,
		// autoHeight: true,
		effect: "fade",
		navigation: {
			nextEl: ".glide__arrow--right",
			prevEl: ".glide__arrow--left",
		},
	});

	mm.add("(min-width: 1024px)", () => {
		// desktop setup code here...

		if ($("body").hasClass("page-template-page-voices")) {
			intro = gsap.timeline().addLabel("start");
			if (!Cookies.get("voices-home")) {
				intro.to("#voices-hero-gallery img:first-child", {
					autoAlpha: 1,
					duration: 0.2,
				});
			}

			intro
				.fromTo(
					"#ve-sidebar .col-span-1",
					{ y: "-100px", autoAlpha: 0 },
					{ y: "0px", autoAlpha: 1, stagger: 0.2 },
					"start"
				)
				.fromTo(
					"header > *",
					{
						y: "-100px",
						autoAlpha: 0,
					},
					{
						y: "0px",
						autoAlpha: 1,
						stagger: 0.2,
					},
					"start"
				)
				.fromTo(
					".part1, .part3",
					{
						autoAlpha: 0,
					},
					{
						autoAlpha: 1,
					},
					"start"
				)
				.to(
					"#sidebar-line",
					{
						height: "100%",
						// onComplete: () => {
						// 	voicesHero();
						// },
					},
					"-=.3"
				);

			const imgs = gsap.utils.toArray("#voices-hero-gallery img");
			if (!Cookies.get("voices-home")) {
				const delay = 0.5;

				imgs.forEach((img, i) => {
					intro.set(img, { autoAlpha: 1 }, delay * i);
					intro.set(img, { autoAlpha: 0 }, delay * (i + 1));
				});

				Cookies.set("voices-home", true, { expires: 1 });
			}

			intro
				.to(".part2", {
					width: "33.556%",
					paddingRight: "1rem",
				})
				.to(".part2", {
					autoAlpha: 1,
					onComplete: () => {
						// close voices, fade out and bring content up
						let closeVoices = gsap
							.timeline({
								defaults: {
									ease: "none",
								},
							})
							.to(".part2", {
								autoAlpha: 0,
								duration: 1,
							})
							.to(".part2", {
								width: "0px",
								paddingRight: "0",
								duration: 1,
							})
							.to("#voices-hero-heading-outer", {
								autoAlpha: 0,
								duration: 2,
								height: "8rem",
							})
							.to({}, { duration: 1 });

						ScrollTrigger.create({
							animation: closeVoices,
							id: "hero",
							trigger: "#voices-hero-wrapper",
							start: "top top",
							end: "+=200%",
							pin: true,
							pinSpacing: false,
							scrub: true,
							// markers: true,
							onEnter: () => {
								$("#voices-hero-heading")
									.addClass("justify-end")
									.removeClass("justify-start");
							},
							onEnterBack: () => {
								$("#voices-hero-heading")
									.removeClass("justify-end")
									.addClass("justify-start");
							},
						});
					},
				});

			// sticky heading over page content
			ScrollTrigger.create({
				id: "title",
				trigger: "#voices-content",
				start: "top top",
				end: "bottom bottom",
				pin: "#voices-sticky",
				// markers: true,
			});

			// fade in about content
			let aboutFadeIn = gsap.timeline().to("#voices-about > *", {
				autoAlpha: 1,
				y: 0,
				stagger: 0.2,
			});

			ScrollTrigger.create({
				animation: aboutFadeIn,
				id: "about",
				trigger: "#voices-about",
				start: "top 40%",
			});
		} else {
			intro = gsap
				.timeline()
				.set("#ve-hero-image", {
					left: $(window).width(),
				})
				.addLabel("start")
				.fromTo(
					"#ve-sidebar .col-span-1",
					{ y: "-100px", autoAlpha: 0 },
					{ y: "0px", autoAlpha: 1, stagger: 0.2 },
					"start"
				)
				.fromTo(
					"header > *",
					{
						y: "-100px",
						autoAlpha: 0,
					},
					{
						y: "0px",
						autoAlpha: 1,
						stagger: 0.2,
					},
					"start"
				)
				.fromTo(
					"#ve-hero-content h1, #ve-hero-content .bottom > *",
					{
						y: "100px",
						autoAlpha: 0,
					},
					{
						y: "0px",
						autoAlpha: 1,
						stagger: 0.2,
					},
					"start"
				)
				.set("#ve-hero-image", { autoAlpha: 1 })
				.to("#ve-hero-image", {
					left: "60%",
					duration: 1,
					ease: "power1.out",
					// delay: 1,
				})
				.to(
					"#sidebar-line",
					{
						height: "100%",
						// onComplete: () => voicesHero(),
					},
					"-=.3"
				);

			let intro = gsap
				.timeline({
					paused: true,
					defaults: {
						ease: "none",
					},
				})
				.fromTo(
					"#ve-hero-image",
					{
						left: "60%",
					},
					{
						left: 0,
					},
					0
				)
				.fromTo(
					"#ve-hero-image img",
					{
						left: "0%",
						// width: "120%",
					},
					{
						left: "-20%",
						// width: "120%",
					},
					0
				);

			ScrollTrigger.create({
				animation: intro,
				trigger: "#ve-hero-content",
				start: "top top",
				end: "+=2000",
				pin: "#ve-hero",
				pinSpacing: true,
				scrub: 0.2,
				// markers: true,
			});

			ScrollTrigger.create({
				id: "ve-sidebar",
				trigger: "#top",
				start: "top top",
				endTrigger: ".next-section",
				end: "bottom bottom",
				pin: "#ve-sidebar",
				// markers: true,
				refreshPriority: -1,
			});

			// if (!$("body").hasClass("page-template-page-voices-category")) {

			// }
		}
	});

	mm.add("(max-width: 1023px)", () => {
		// mobile setup code here...

		if ($("body").hasClass("page-template-page-voices")) {
			// $("body").addClass("overflow-hidden");
			intro = gsap.timeline({
				// onComplete: () => voicesHero(),
			});

			if (Cookies.get("voices-home")) {
				intro.set("#voices-hero-gallery img", { visibility: "hidden" });
			}

			intro
				.fromTo(
					"header > *:not(#voices-logo-header)",
					{
						y: "-100px",
						autoAlpha: 0,
					},
					{
						y: "0px",
						autoAlpha: 1,
						stagger: 0.2,
					},
					0
				)
				.fromTo(
					".part0",
					{
						autoAlpha: 0,
					},
					{
						autoAlpha: 1,
					},
					"start"
				);

			const imgs = gsap.utils.toArray("#voices-hero-gallery img");
			if (!Cookies.get("voices-home")) {
				const delay = 0.5;

				imgs.forEach((img, i) => {
					intro.set(img, { visibility: "visible" }, delay * i);
					intro.set(img, { visibility: "hidden" }, delay * (i + 1));
				});

				Cookies.set("voices-home", true, { expires: 1 });
			}

			intro
				.addLabel("swipe")
				.fromTo(
					"#voices-hero-inner",
					{
						yPercent: 0,
					},
					{
						yPercent: -100,
						ease: "power1.in",
						duration: 2,
					},
					"swipe"
				)
				.fromTo(
					"#voices-hero-wrapper",
					{
						height: "100vh",
					},
					{
						height: "50vh",
						ease: "power1.in",
						duration: 2,
					},
					"swipe"
				)
				.fromTo(
					"#voices-hero-heading-outer",
					{
						width: "100%",
						autoAlpha: 1,
						y: 0,
					},
					{
						width: 100,
						autoAlpha: 0,
						y: 50,
						ease: "power1.in",
						duration: 1.4,
						immediateRender: false,
					},
					"swipe"
				)
				.fromTo(
					"#voices-logo-header",
					{
						autoAlpha: 0,
					},
					{
						autoAlpha: 1,
						duration: 1,
						delay: 1.3,
					},
					"swipe"
				)
				.fromTo(
					"#voices-image .prose-voices",
					{
						autoAlpha: 0,
						y: 60,
					},
					{
						autoAlpha: 1,
						y: 0,
						duration: 1,
						onStart: () => {
							gsap.timeline({
								scrollTrigger: {
									id: "about",
									trigger: "#voices-about",
									start: "top bottom",
									// markers: true,
								},
							}).to("#voices-about > *", {
								autoAlpha: 1,
								y: 0,
								stagger: 0.2,
								duration: 1,
							});
						},
					},
					"swipe+=2"
				);

			// let parallax = gsap.timeline().fromTo(
			// 	"#voices-image img",
			// 	{
			// 		yPercent: -10,
			// 	},
			// 	{
			// 		yPercent: 10,
			// 		ease: "none",
			// 	}
			// );

			// ScrollTrigger.create({
			// 	animation: parallax,
			// 	trigger: "#voices-image .img-wrapper",
			// 	start: "top 66.7%",
			// 	end: "bottom top",
			// 	scrub: 0.2,
			// 	// markers: true,
			// });
		} else {
			intro = gsap
				.timeline({
					// onComplete: () => voicesHero(),
				})
				.fromTo(
					"header > *",
					{
						y: "-100px",
						autoAlpha: 0,
					},
					{
						y: "0px",
						autoAlpha: 1,
						stagger: 0.2,
					},
					0
				)
				.fromTo(
					"#ve-hero-content h1, #ve-hero-content .bottom > *",
					{
						y: "100px",
						autoAlpha: 0,
					},
					{
						y: "0px",
						autoAlpha: 1,
						stagger: 0.2,
					},
					0
				);

			let parallax = gsap.timeline().fromTo(
				"#ve-hero-image img",
				{
					top: "0%",
				},
				{
					top: "30%",
					ease: "none",
				}
			);

			ScrollTrigger.create({
				animation: parallax,
				trigger: "#top",
				start: "top top",
				scrub: true,
				// markers: true,
			});
		}
	});

	init();

	$(document).on("click", "#voicesSignupOpen", function () {
		$("#openSignupOverlay").trigger("click");
	});

	return intro;
}

// export default function voicesHero() {
// 	let mm = gsap.matchMedia();

// 	mm.add("(min-width: 1024px)", () => {
// 		// desktop setup code here...

// 		if ($("body").hasClass("page-template-page-voices")) {
// 			// const imgs = gsap.utils.toArray("#voices-hero-gallery img");
// 			// const tl = gsap.timeline();
// 			// const delay = 0.5;
// 			// imgs.forEach((img, i) => {
// 			// 	// Show img
// 			// 	tl.set(img, { visibility: "visible" }, delay * i);
// 			// 	// Hide img
// 			// 	tl.set(img, { visibility: "hidden" }, delay * (i + 1));
// 			// });
// 			// tl.to(".part2", {
// 			// 	width: "33.556%",
// 			// 	paddingRight: "1rem",
// 			// })
// 			// 	.to(".part2", {
// 			// 		autoAlpha: 1,
// 			// 		onComplete: () => {
// 			// 			$("#ve-sidebar").removeClass(
// 			// 				"!bg-transparent  mix-blend-difference"
// 			// 			);
// 			// 			// .addClass("fixed top-0");
// 			// 			$("#ve-sidebar .col-span-1").addClass(
// 			// 				"mix-blend-difference"
// 			// 			);
// 			// 			$("#voices-hero-heading")
// 			// 				.addClass("justify-end")
// 			// 				.removeClass("justify-start");
// 			// 			gsap.timeline({
// 			// 				defaults: {
// 			// 					ease: "none",
// 			// 				},
// 			// 				scrollTrigger: {
// 			// 					id: "hero",
// 			// 					trigger: "#voices-hero-wrapper",
// 			// 					start: "top top",
// 			// 					end: "+=200%",
// 			// 					pin: true,
// 			// 					pinSpacing: false,
// 			// 					scrub: true,
// 			// 					// markers: true,
// 			// 				},
// 			// 			})
// 			// 				.to(".part2", {
// 			// 					autoAlpha: 0,
// 			// 					duration: 1,
// 			// 				})
// 			// 				.to(".part2", {
// 			// 					width: "0px",
// 			// 					paddingRight: "0",
// 			// 					duration: 1,
// 			// 				})
// 			// 				// .to("#voices-image", {
// 			// 				// 	top: "0",
// 			// 				// 	duration: 2,
// 			// 				// })
// 			// 				.to("#voices-hero-heading-outer", {
// 			// 					autoAlpha: 0,
// 			// 					duration: 2,
// 			// 					height: "8rem",
// 			// 				})
// 			// 				.to({}, { duration: 1 });
// 			// 			ScrollTrigger.create({
// 			// 				id: "title",
// 			// 				trigger: "#voices-content",
// 			// 				start: "top top",
// 			// 				end: "bottom bottom",
// 			// 				pin: "#voices-sticky",
// 			// 				// markers: true,
// 			// 			});
// 			// 			gsap.timeline({
// 			// 				scrollTrigger: {
// 			// 					id: "about",
// 			// 					trigger: "#voices-about",
// 			// 					start: "top 40%",
// 			// 					// markers: true,
// 			// 				},
// 			// 			}).to("#voices-about > *", {
// 			// 				autoAlpha: 1,
// 			// 				y: 0,
// 			// 				stagger: 0.2,
// 			// 			});
// 			// 		},
// 			// 	})
// 			// 	.to(
// 			// 		"#voices-hero-sidebar > div",
// 			// 		{
// 			// 			height: "100%",
// 			// 		},
// 			// 		"-=.5"
// 			// 	);
// 		} else {
// 			let intro = gsap
// 				.timeline({
// 					paused: true,
// 					defaults: {
// 						ease: "none",
// 					},
// 				})
// 				.fromTo(
// 					"#ve-hero-image",
// 					{
// 						left: "60%",
// 					},
// 					{
// 						left: 0,
// 					},
// 					0
// 				)
// 				.fromTo(
// 					"#ve-hero-image img",
// 					{
// 						left: "0%",
// 						// width: "120%",
// 					},
// 					{
// 						left: "-20%",
// 						// width: "120%",
// 					},
// 					0
// 				);

// 			ScrollTrigger.create({
// 				animation: intro,
// 				trigger: "#ve-hero-content",
// 				start: "top top",
// 				end: "+=2000",
// 				pin: "#ve-hero",
// 				pinSpacing: true,
// 				scrub: 0.2,
// 				// markers: true,
// 			});

// 			ScrollTrigger.create({
// 				trigger: "#top",
// 				start: "top top",
// 				endTrigger: ".next-section",
// 				end: "bottom bottom",
// 				pin: "#ve-sidebar",
// 				// markers: true,
// 			});
// 		}

// 		// gsap.fromTo(
// 		// 	"#ve-hero-image img",
// 		// 	{
// 		// 		top: "0%",
// 		// 	},
// 		// 	{
// 		// 		top: "20%",
// 		// 		ease: "none",
// 		// 		scrollTrigger: {
// 		// 			start: 2000,
// 		// 			end: 3000,
// 		// 			scrub: true,
// 		// 			// markers: true,
// 		// 		},
// 		// 	}
// 		// );

// 		return () => {
// 			// make sure to clean up event listeners in the cleanup function!
// 			gsap.set("#ve-hero-image", { clearProps: "all" });
// 			gsap.set("#ve-hero-image img", { clearProps: "all" });
// 		};
// 	});

// 	mm.add("(max-width: 1023px)", () => {
// 		// mobile setup code here...

// 		if ($("body").hasClass("page-template-page-voices")) {
// 			// const imgs = gsap.utils.toArray("#voices-hero-gallery img");
// 			// const tl = gsap.timeline();
// 			// const delay = 0.5;
// 			// let arrayLength = imgs.length;
// 			// imgs.forEach((img, i) => {
// 			// 	tl.set(img, { visibility: "visible" }, delay * i);
// 			// 	// if (arrayLength < i + 1) {
// 			// 	tl.set(img, { visibility: "hidden" }, delay * (i + 1));
// 			// 	// }
// 			// });
// 			// tl.addLabel("swipe")
// 			// 	.fromTo(
// 			// 		"#voices-hero-inner",
// 			// 		{
// 			// 			yPercent: 0,
// 			// 			// height: "100%",
// 			// 		},
// 			// 		{
// 			// 			yPercent: -100,
// 			// 			// height: "0%",
// 			// 			ease: "power1.in",
// 			// 			duration: 2,
// 			// 		},
// 			// 		"swipe"
// 			// 	)
// 			// 	.fromTo(
// 			// 		"#voices-hero-wrapper",
// 			// 		{
// 			// 			height: "100vh",
// 			// 		},
// 			// 		{
// 			// 			height: "50vh",
// 			// 			ease: "power1.in",
// 			// 			duration: 2,
// 			// 		},
// 			// 		"swipe"
// 			// 	)
// 			// 	.fromTo(
// 			// 		"#voices-hero-heading-outer",
// 			// 		{
// 			// 			width: "100%",
// 			// 			// y: 0,
// 			// 			// top: 0,
// 			// 			autoAlpha: 1,
// 			// 		},
// 			// 		{
// 			// 			width: 100,
// 			// 			// y: "50vh",
// 			// 			// top: "90vh",
// 			// 			autoAlpha: 0,
// 			// 			ease: "power1.in",
// 			// 			duration: 1.5,
// 			// 		},
// 			// 		"swipe"
// 			// 	)
// 			// 	.fromTo(
// 			// 		"#voices-logo-header",
// 			// 		{
// 			// 			autoAlpha: 0,
// 			// 		},
// 			// 		{
// 			// 			autoAlpha: 1,
// 			// 			duration: 1,
// 			// 			delay: 1.3,
// 			// 		},
// 			// 		"swipe"
// 			// 	)
// 			// 	.fromTo(
// 			// 		"#voices-image .prose-voices",
// 			// 		{
// 			// 			autoAlpha: 0,
// 			// 			y: 60,
// 			// 		},
// 			// 		{
// 			// 			autoAlpha: 1,
// 			// 			y: 0,
// 			// 			duration: 1,
// 			// 			onStart: () => {
// 			// 				// $("body").removeClass("overflow-hidden");
// 			// 				let parallax = gsap.timeline().fromTo(
// 			// 					"#voices-image img",
// 			// 					{
// 			// 						yPercent: -10,
// 			// 					},
// 			// 					{
// 			// 						yPercent: 10,
// 			// 						ease: "none",
// 			// 					}
// 			// 				);
// 			// 				ScrollTrigger.create({
// 			// 					animation: parallax,
// 			// 					trigger: "#voices-image .img-wrapper",
// 			// 					start: "top 66.7%",
// 			// 					end: "bottom top",
// 			// 					scrub: 0.2,
// 			// 					// markers: true,
// 			// 				});
// 			// 				gsap.timeline({
// 			// 					scrollTrigger: {
// 			// 						id: "about",
// 			// 						trigger: "#voices-about",
// 			// 						start: "top bottom",
// 			// 						// markers: true,
// 			// 					},
// 			// 				}).to("#voices-about > *", {
// 			// 					autoAlpha: 1,
// 			// 					y: 0,
// 			// 					stagger: 0.2,
// 			// 					duration: 1,
// 			// 				});
// 			// 			},
// 			// 		},
// 			// 		"swipe+=2"
// 			// 	);
// 		} else {
// 			let parallax = gsap.timeline().fromTo(
// 				"#ve-hero-image img",
// 				{
// 					top: "0%",
// 				},
// 				{
// 					top: "30%",
// 					ease: "none",
// 				}
// 			);

// 			ScrollTrigger.create({
// 				animation: parallax,
// 				trigger: "#top",
// 				start: "top top",
// 				scrub: true,
// 				// markers: true,
// 			});
// 		}

// 		return () => {
// 			// make sure to clean up event listeners in the cleanup function!
// 			gsap.set("#ve-hero-image img", { clearProps: "all" });
// 		};
// 	});
// }
