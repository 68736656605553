import jQuery from "jquery";
window.$ = window.jQuery = jQuery;

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

import * as THREE from "three";

// import { GUI } from "three/addons/libs/lil-gui.module.min.js";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { Sky } from "three/examples/jsm/objects/Sky";

export default function sunNeverSets() {
	console.log("in");
	let camera, scene, renderer;

	let sky, sun;

	initSun();
	render();
	snsSidebar();

	function initSky() {
		// Add Sky
		sky = new Sky();
		sky.scale.setScalar(900000);
		scene.add(sky);

		sun = new THREE.Vector3();

		/// GUI

		const effectController = {
			turbidity: 20,
			rayleigh: 2,
			mieCoefficient: 0.1,
			mieDirectionalG: 0.85,
			elevation: 0,
			azimuth: 180,
			exposure: 1,
		};

		function guiChanged() {
			const uniforms = sky.material.uniforms;
			uniforms["turbidity"].value = effectController.turbidity;
			uniforms["rayleigh"].value = effectController.rayleigh;
			uniforms["mieCoefficient"].value = effectController.mieCoefficient;
			uniforms["mieDirectionalG"].value =
				effectController.mieDirectionalG;

			const phi = THREE.MathUtils.degToRad(
				90 - effectController.elevation
			);
			const theta = THREE.MathUtils.degToRad(effectController.azimuth);

			sun.setFromSphericalCoords(1, phi, theta);

			uniforms["sunPosition"].value.copy(sun);

			renderer.toneMappingExposure = effectController.exposure;
			renderer.render(scene, camera);
		}

		let intro = gsap
			.timeline()
			.fromTo(
				"#veg-sidebar .col-span-1",
				{ y: "-100px", autoAlpha: 0 },
				{ y: "0px", autoAlpha: 1, stagger: 0.2 }
			)
			// .fromTo(
			// 	"header > *",
			// 	{
			// 		y: "-100px",
			// 		autoAlpha: 0,
			// 	},
			// 	{
			// 		y: "0px",
			// 		autoAlpha: 1,
			// 		stagger: 0.2,
			// 	}
			// )
			.to(
				"#sidebar-line",
				{
					height: "100%",
					// onComplete: () => {
					// 	voicesHero();
					// },
				},
				"-=.3"
			)
			.fromTo(
				".voice-template-single-voice-globe .sns-fade",
				{ autoAlpha: 0 },
				{ autoAlpha: 1, stagger: 0.2 }
			);

		gsap.fromTo(
			"#sunset-fade",
			{ autoAlpha: 0 },
			{
				autoAlpha: 0.15,
				scrollTrigger: {
					trigger: ".sns-intro",
					// markers: true,
					start: "top bottom",
					end: "top 60%",
					scrub: true,
				},
			}
		);

		ScrollTrigger.create({
			trigger: "body",
			// markers: true,
			start: "top 1px",
			end: "top 75%",
			endTrigger: "#chartdiv",
			// pin: "canvas",
			// pinSpacer: false,
			onToggle: (self) =>
				console.log("toggled, isActive:", self.isActive),
			onUpdate: (self) => {
				// console.log("progress:", self.progress.toFixed(3) * 1.8);

				function convertScale(value) {
					// Map the input value from the 0-1 scale to the 0.8-1 scale
					return 0.85 + 0.145 * value;
				}

				// Example usage:
				const inputValue = self.progress.toFixed(3); // Replace this with your input value
				const convertedValue = convertScale(inputValue);
				// console.log(convertedValue); // This will output the converted value
				let prog = convertedValue;
				// let prog = self.progress.toFixed(3) * 1.25 - 0.21;
				// if (prog > 1) {
				// 	prog = 1;
				// } else if (prog <= 0) {
				// 	prog = 0;
				// }

				// let invert = prog + 1 - 2 * prog;

				// console.log(self.progress.toFixed(3));
				effectController.mieDirectionalG = prog;

				guiChanged();

				// console.log(effectController.mieDirectionalG);
			},
		});
	}

	function initSun() {
		camera = new THREE.PerspectiveCamera(
			60,
			window.innerWidth / window.innerHeight - 0.2,
			100,
			2000000
		);
		camera.position.set(0, -300, 2000);

		// console.log(window.innerWidth / window.innerHeight - 0.1);

		scene = new THREE.Scene();

		// const helper = new THREE.GridHelper(10000, 2, 0xffffff, 0xffffff);
		// scene.add(helper);

		renderer = new THREE.WebGLRenderer();
		renderer.setPixelRatio(window.devicePixelRatio);
		renderer.setSize(window.innerWidth, window.innerHeight);
		renderer.toneMapping = THREE.ACESFilmicToneMapping;
		renderer.toneMappingExposure = 0.5;
		document.getElementById("sunset").appendChild(renderer.domElement);

		const controls = new OrbitControls(camera, renderer.domElement);
		controls.addEventListener("change", render);
		//controls.maxPolarAngle = Math.PI / 2;
		controls.enableZoom = false;
		controls.enablePan = false;

		initSky();

		window.addEventListener("resize", onWindowResize);
	}

	function onWindowResize() {
		camera.aspect = window.innerWidth / window.innerHeight;
		camera.updateProjectionMatrix();

		renderer.setSize(window.innerWidth, window.innerHeight);

		// console.log("resize");

		render();
	}

	function render() {
		renderer.render(scene, camera);
	}

	gsap.utils.toArray(".sns-fade").forEach((effect) => {
		gsap.fromTo(
			effect,
			{ autoAlpha: 1 },
			{
				autoAlpha: 0,
				immediateRender: false,
				scrollTrigger: {
					trigger: effect,
					start: "top 25%",
					end: "top 15%",
					scrub: 2,
					// markers: true,
				},
			}
		);
	});

	setTimeout(function () {
		ScrollTrigger.create({
			id: "veg-sidebar",
			trigger: "body",
			start: "top top",
			endTrigger: ".veg-pb",
			end: "bottom bottom",
			pin: "#veg-sidebar",
			pinSpacing: false,
			// markers: true,
			// refreshPriority: -5,
		});
	}, 5000);

	ScrollTrigger.create({
		id: "sunset",
		trigger: "body",
		start: "top top",
		endTrigger: ".veg-pb",
		end: "bottom bottom",
		pin: "#sunset",
		pinSpacing: false,
		// markers: true,
		// refreshPriority: -1,
	});

	function snsSidebar() {
		let openSnsSidebar = gsap
			.timeline({ paused: true })
			.fromTo(
				"#sns-sidebar",
				{ x: "100%" },
				{ x: 0, duration: 1, ease: "power1.out" }
			)
			.fromTo(
				"#sns-inner > *",
				{ y: 50, autoAlpha: 0 },
				{ y: 0, autoAlpha: 1, stagger: 0.05 },
				"-=.25"
			)
			.fromTo("#sns-close", { autoAlpha: 0 }, { autoAlpha: 1 })
			.fromTo("#sns-bg", { autoAlpha: 0 }, { autoAlpha: 1 }, 0);

		let closeSnsSidebar = gsap
			.timeline({
				paused: true,
				clearProps: true,
				defaults: { immediateRender: false },
			})
			.fromTo(
				"#sns-sidebar",
				{ x: 0 },
				{ x: "100%", ease: "power1.in" },
				0
			)
			.fromTo("#sns-inner > *", { autoAlpha: 1 }, { autoAlpha: 0 }, 0)
			.fromTo("#sns-close", { autoAlpha: 1 }, { autoAlpha: 0 }, 0)
			.fromTo("#sns-bg", { autoAlpha: 1 }, { autoAlpha: 0 }, 0);

		$(document).on("click", ".sns-button", function () {
			let country = $(this);
			let name = country.data("name");
			let flag = country.data("flag");
			// let prevname = country.data("prevname");
			// let colonised = country.data("colonised");
			// let independence = country.data("independence");
			let description = country.siblings(".sns-note").html();

			// console.log(description);

			$("#sns-name").append(name);
			$("#sns-flag").attr("src", flag);
			// $("#sns-prevname").append(prevname);
			// $("#sns-colonised").append(colonised);
			// $("#sns-independence").append(independence);
			$("#sns-description").append(description);

			openSnsSidebar.seek(0).play();
		});

		$("#sns-close").on("click", function () {
			closeSnsSidebar
				.seek(0)
				.play()
				.eventCallback("onComplete", () => {
					$("#sns-name").empty();
					$("#sns-flag").attr("src", "");
					// $("#sns-prevname").empty();
					// $("#sns-colonised").empty();
					// $("#sns-independence").empty();
					$("#sns-description").empty();
				});
		});

		$("#sns-bg").on("click", () => $("#sns-close").trigger("click"));
	}
}
